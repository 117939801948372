import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Select, Spin, Tag, message, notification, Typography, Empty } from "antd";

import { getGraphData, getMaterialPropertyDetaiByID, getTestMethodOptions } from "../axios";
import DefaultBreadCrumb from "../components/breadcrumb";
import Graph from "../components/materialComparison/Graph";
import Variables from "../components/materialComparison/Variables";
import { runAutoCalculation } from "../components/utils/calculatorUtils";

const { Option, OptGroup } = Select;

const MaterialComparison = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const graphDetails = useSelector((state) => state.graphData);

  const [materialDetails, setMaterialDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGraphType, setSelectedGraphType] = useState(1);
  const [graphPlotData, setGraphPlotData] = useState({});
  const [axesLabels, setAxesLabels] = useState({ x: "", y: "" });
  const [hasGraphMissingVariables, setHasGraphMissingVariables] = useState(false);
  const [propertyColumns, setPropertyColumns] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [testMethods, setTestMethods] = useState([]);
  const [selectedTestMethod, setSelectedTestMethod] = useState();
  const [dataPts, setDataPts] = useState([]);
  const [graphTypePresent, setGraphTypePresent] = useState(true);
  const [title, setTitle] = useState();

  const colors = useMemo(
    () => [
      "#0000FF", // Blue
      "#FFA500", // Orange
      "#800080", // Purple
      "#00FFFF", // Cyan
      "#543310", // Brown
      "#00FF00", // Lime
      "#4B0082", // Indigo
    ],
    []
  );

  const symbols = useMemo(() => ["circle", "rect", "triangle", "diamond", "pin", "arrow", "roundRect"], []);

  const getTitle = useCallback(
    (value) => {
      if (typeof value === "string" && (value.startsWith("TTM-") || value.startsWith("STM-"))) {
        const method = testMethods.responseData?.find((method) => method.id === value) || { name: "", id: "" };
        return `${method.name} (${method.id})`;
      } else {
        return graphDetails.data?.graphData?.find((gd) => gd.graphId === value)?.graphDescription || "";
      }
    },
    [graphDetails.data?.graphData, testMethods.responseData]
  );

  const getComparisonData = useCallback(
    (id, materialData, graphDetail, gIndex) => {
      if (Object.keys(materialData.material).length > 0) {
        let graphIndex = graphDetail.graphData.findIndex((temp) => {
          return temp.graphId === id;
        });

        let loopedVariables = graphDetail.loopingVariables;
        let initialEquations = graphDetail.graphInitialEquation;
        let graphData = graphDetail.graphData[graphIndex];
        const coordinatesForFirstGraph = [];
        const coordinatesForSecondGraph = [];
        let calculatedValues = {};
        let errors = [];

        initialEquations.forEach((record) => {
          if (record.defaultValue) {
            calculatedValues[record.equationDbColumnName] = record.defaultValue;
          } else {
            Object.keys(materialData).forEach((module) => {
              const moduleData = materialData[module];

              // Check if moduleData is an object and has propertyData
              if (moduleData && moduleData.propertyData) {
                moduleData.propertyData.forEach((tempProperty) => {
                  let key = Object.keys(tempProperty)[0];

                  if (tempProperty[key]["valueTypeArray"]) {
                    tempProperty[key]["value"].forEach((tempArrayProperty) => {
                      let innerkey = Object.keys(tempArrayProperty)[0];

                      if (
                        tempArrayProperty[innerkey]["value"] !== null &&
                        tempArrayProperty[innerkey]["value"] !== "-"
                      ) {
                        calculatedValues[innerkey] = tempArrayProperty[innerkey]["value"];
                      } else {
                        let isExists = errors.findIndex((temp) => temp === innerkey);
                        if (isExists === -1) {
                          errors.push(innerkey);
                        }
                      }
                    });
                  } else {
                    if (tempProperty[key]["value"] !== null) {
                      calculatedValues[key] = tempProperty[key]["value"];
                    } else if (!tempProperty[key]["value"]) {
                      if (["solidsDryGelDensity", "solidsDryGelRadius", "contactAngle"].includes(key)) {
                        calculatedValues[key] = 1;
                      }

                      let isExists = errors.findIndex((temp) => temp === key);

                      if (isExists === -1) {
                        errors.push(key);
                      }
                    }
                  }
                });
              }
            });

            // Handle loopingVariableUsed and equationDbColumnName as before
            if (record.loopingVariableUsed === false) {
              calculatedValues[record.equationDbColumnName] = runAutoCalculation(calculatedValues, record.equation);
            } else if (record.loopingVariableUsed) {
              let useDefaultLoopingVariables = false;
              let loop = [];
              let tempLoopVar = record.loopingVariable;
              calculatedValues[record.equationDbColumnName] = [];

              if (loopedVariables[tempLoopVar] !== undefined) {
                loop = loopedVariables[tempLoopVar];
                useDefaultLoopingVariables = true;
              } else if (loopedVariables[tempLoopVar] === undefined && calculatedValues[tempLoopVar] !== undefined) {
                loop = calculatedValues[tempLoopVar];
                useDefaultLoopingVariables = false;
              }

              loop.forEach((loopItem, loopIndex) => {
                let tempcalculatedValues = calculatedValues;

                if (useDefaultLoopingVariables === true) {
                  tempcalculatedValues[tempLoopVar] = loopItem;
                }

                let tempVal = runAutoCalculation(tempcalculatedValues, record.equation, loopIndex);
                calculatedValues[record.equationDbColumnName][loopIndex] = tempVal;
              });
            }
          }
        });

        // Calulating  --- Running loop for x and y values
        if (graphData.loopVariable) {
          let tempLoopVar = graphData.loopVariable;

          if (loopedVariables[tempLoopVar]) {
            loopedVariables[tempLoopVar].forEach((loopItem, loopIndex) => {
              let tempcalculatedValues = calculatedValues;
              tempcalculatedValues[tempLoopVar] = loopItem;
              let tempEquationResultX = runAutoCalculation(tempcalculatedValues, graphData["xAxisEquation"], loopIndex);
              tempcalculatedValues["X"] = tempEquationResultX;
              let tempEquationResultY = runAutoCalculation(tempcalculatedValues, graphData["yAxisEquation"], loopIndex);
              coordinatesForFirstGraph.push([tempEquationResultX, tempEquationResultY]);

              if (graphData.graphType === "dual") {
                let x1AxisEquation = runAutoCalculation(tempcalculatedValues, graphData["x1AxisEquation"], loopIndex);
                tempcalculatedValues["X"] = x1AxisEquation;
                let y1AxisEquation = runAutoCalculation(tempcalculatedValues, graphData["y1AxisEquation"], loopIndex);
                coordinatesForSecondGraph.push([x1AxisEquation, y1AxisEquation]);
              }
            });
          }
        }

        const tempGraphData = [
          {
            name:
              `${materialData.material.header.name} (${materialData.material.header.key}) ` +
              (graphData.firstLegendLabel || ""),
            type: "line",
            smooth: true,
            itemStyle: {
              color: colors[gIndex % colors.length],
            },
            symbol: symbols[gIndex % symbols.length],
            symbolSize: 6,
            data: coordinatesForFirstGraph,
          },
        ];

        if (graphData.graphType === "dual") {
          tempGraphData.push({
            name:
              `${materialData.material.header.name} (${materialData.material.header.key}) ` +
              (graphData.secondLegendLabel || ""),
            type: "line",
            smooth: true,
            lineStyle: {
              type: "dashed",
            },
            itemStyle: {
              color: colors[gIndex % colors.length],
            },
            symbol: symbols[gIndex % symbols.length],
            symbolSize: 6,
            data: coordinatesForSecondGraph,
          });
        }

        const missingVariables = errors?.filter(
          (field) =>
            graphData["xAxisEquation"]?.includes(field) ||
            graphData["yAxisEquation"]?.includes(field) ||
            graphData["x1AxisEquation"]?.includes(field) ||
            graphData["y1AxisEquation"]?.includes(field)
        );

        return {
          graphData: graphData,
          graphVariables: calculatedValues,
          graphMissingVariables: missingVariables,
          graphChartData: {
            xAxisLabel: graphData.xAxisLabel,
            yAxisLabel: graphData.yAxisLabel,
            graphPlotData: tempGraphData,
          },
        };
      }
    },
    [colors, symbols]
  );

  const fetchMaterialDetails = useCallback(
    async (ids) => {
      try {
        setLoading(true);
        const promises = [dispatch(getMaterialPropertyDetaiByID({ ids }))]; // Dispatch action with selectedIds as an array
        const responses = await Promise.all(promises);
        setMaterialDetails(responses[0].data.responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching material details:", error);
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setTitle(getTitle(selectedGraphType));
  }, [getTitle, selectedGraphType]);

  useEffect(() => {
    const selectedIds = id ? id.split(",").map((id) => id.trim()) : [];

    if (selectedIds.length > 0) {
      dispatch(getGraphData());
      fetchMaterialDetails(selectedIds);
    } else {
      setMaterialDetails([]);
    }
  }, [dispatch, fetchMaterialDetails, id]);

  useEffect(() => {
    if (materialDetails?.length > 0 && graphDetails?.data?.graphData?.length > 0) {
      const data = materialDetails.map((material, index) => {
        return getComparisonData(selectedGraphType, material, graphDetails.data, index);
      });
      setGraphTypePresent(true);
      setSelectedTestMethod();
      setTitle(getTitle(selectedGraphType));
      setGraphPlotData(data.flatMap((d) => d.graphChartData.graphPlotData));
      setAxesLabels({ x: data[0].graphChartData.xAxisLabel, y: data[0].graphChartData.yAxisLabel });
      setHasGraphMissingVariables(data.some((d) => d.graphMissingVariables.length > 0));

      setPropertyColumns([
        {
          title: <b>Variable</b>,
          width: "300",
          dataIndex: "variable",
          key: "variable",
          fixed: "left",
          render: (text) => (
            <Typography.Text strong type={text === "Missing Variables" ? "danger" : null}>
              {text}
            </Typography.Text>
          ),
        },
        ...materialDetails.map((md, i) => {
          return {
            title: (
              <b style={{ color: i <= 10 ? colors[i] : "#000" }}>
                {md.material.header.name + " (" + md.material.header.key + ")"}
              </b>
            ),
            dataIndex: md.material.header.key,
            key: md.material.header.key,
            textWrap: "word-break",
            render: (text) => (
              <div
                style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "break-spaces", maxWidth: 300 }}
              >
                {text}
              </div>
            ),
          };
        }),
      ]);
      const gV = data.map((d) => d.graphVariables);
      const keys = Object.keys(gV[0]);
      const pD = [
        {
          variable: "Description",
          ...materialDetails
            .map((md, i) => {
              return {
                [md.material.header.key.toString()]: md.material.header.description,
              };
            })
            .reduce((acc, obj) => {
              return { ...acc, ...obj };
            }, {}),
        },
        ...Object.values(gV[0]).map((_, index) => {
          return {
            variable: keys[index],
            ...materialDetails
              .map((md, i) => {
                const key = keys[index];
                const val = gV[i][key];
                return { [md.material.header.key.toString()]: Array.isArray(val) ? val.join(",\n") : val };
              })
              .reduce((acc, obj) => {
                return { ...acc, ...obj };
              }, {}),
          };
        }),
        {
          variable: "Missing Variables",
          ...materialDetails
            .map((md, i) => {
              return {
                [md.material.header.key.toString()]:
                  data[i].graphMissingVariables.length > 0 ? data[i].graphMissingVariables.join("\n") : "-",
              };
            })
            .reduce((acc, obj) => {
              return { ...acc, ...obj };
            }, {}),
        },
      ];
      setPropertyData(pD);
    }
  }, [colors, getComparisonData, getTitle, graphDetails.data, materialDetails, selectedGraphType]);

  const fetchTestMethods = useCallback(async () => {
    setLoading(true);
    try {
      const testMethodOptionresponse = await dispatch(getTestMethodOptions());
      setTestMethods(testMethodOptionresponse.data || []);
    } catch (error) {
      message.error("Failed to fetch test methods");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchTestMethods();
  }, [fetchTestMethods]);

  const convertAvgJsonToGraphArray = (data) => {
    const load = data.loadStress.map((y, i) => [data.loadStrain[i], y]);
    const unload = data.unloadStress.map((y, i) => [data.unloadStrain[i], y]);

    return {
      LOAD: load,
      UNLOAD: unload,
    };
  };

  const findFirstChildOfAverage = (finalJSON) => {
    if (finalJSON && finalJSON.average && finalJSON.average.length > 0) {
      return finalJSON.average[0];
    }
    return null;
  };

  const clearDataPoints = () => {
    setDataPts([]);

    setGraphTypePresent(false);
  };

  const createSeries = (name, data, color, lineType, index) => {
    return {
      name: name,
      type: "line",
      smooth: true,
      data: data,
      itemStyle: {
        color: color,
      },
      lineStyle: {
        type: lineType,
      },
      symbol: symbols[index % symbols.length],
      symbolSize: 6,
    };
  };

  const handleAverage = async (finalJSON, materialID, materialName, index) => {
    try {
      if (!finalJSON) {
        clearDataPoints();
        return;
      }
      const firstChildOfAverage = findFirstChildOfAverage(finalJSON);

      if (firstChildOfAverage) {
        const graphObject = convertAvgJsonToGraphArray(firstChildOfAverage);
        const loadSeries = createSeries(
          `Load Avg. - ${materialName} (${materialID})`,
          graphObject.LOAD,
          colors[index],
          "solid",
          index
        );
        const unloadSeries = createSeries(
          `Unload Avg. - ${materialName} (${materialID})`,
          graphObject.UNLOAD,
          colors[index],
          "dotted",
          index
        );

        setDataPts((prevDataPts) => [...prevDataPts, loadSeries, unloadSeries]);
        setGraphTypePresent(false);
      }
    } catch (error) {
      console.error("Error:", error);
      notification.open({
        message: "Something went wrong!",
        type: "error",
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTestMethodChange = (value) => {
    try {
      clearDataPoints();
      const materialResponse = materialDetails || [];
      const jsonPaths = [];
      let hasMatchingTestMethod = false;

      materialResponse.forEach((data) => {
        if (data.curationData && Array.isArray(data.curationData.materialCurationData)) {
          data.curationData.materialCurationData.forEach((curation) => {
            if (curation.testMethod === value) {
              hasMatchingTestMethod = true;
              const finalJSON = curation.finalJSON;
              const materialID = curation.materialID;
              const materialName = curation.name;
              if (finalJSON !== null) {
                jsonPaths.push({ finalJSON, materialID, materialName });
              } else {
                clearDataPoints();
              }
            }
          });
        }
      });

      if (hasMatchingTestMethod && jsonPaths.length > 0) {
        setLoading(true);
        jsonPaths.forEach((json, index) => {
          handleAverage(json.finalJSON, json.materialID, json.materialName, index);
        });
      } else {
        clearDataPoints();
        setLoading(false);
      }
    } catch (error) {
      message.error("Failed to fetch material details.");
      clearDataPoints();
    }
  };

  const handleChange = (value) => {
    if (typeof value === "string" && (value.startsWith("TTM-") || value.startsWith("STM-"))) {
      handleTestMethodChange(value);
      setSelectedTestMethod(value);
    } else {
      setSelectedGraphType(value);
    }
    setTitle(getTitle(value));
  };

  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <div className="row">
          <DefaultBreadCrumb
            data={[
              { title: "Home", path: "/" },
              { title: "Materials", path: "/materials" },
              { title: "Comparison", path: "/comparison", active: true },
            ]}
          />
        </div>

        <Spin spinning={loading}>
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
              <Select
                style={{ width: 340, marginBottom: 16 }}
                value={selectedTestMethod || selectedGraphType}
                placeholder="Select Option"
                onChange={handleChange}
              >
                <OptGroup label="Graph Types">
                  {graphDetails.data?.graphData?.map((data) => (
                    <Option key={data.graphId} value={data.graphId}>
                      {data.graphName}
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label="Test Methods">
                  {Array.isArray(testMethods.responseData) &&
                    testMethods.responseData.map((method) => (
                      <Option key={method.id} value={method.id}>
                        {method.id} - {method.name}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </div>

            {!graphTypePresent ? (
              <>
                {dataPts.length === 0 ? (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      height: "calc(100vh - 240px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Empty description="No data available to plot the graph" />
                  </div>
                ) : (
                  <Graph xAxisLabel="Strain" yAxisLabel="Stress (kPa)" data={dataPts} />
                )}
              </>
            ) : (
              <>
                {hasGraphMissingVariables ? (
                  <div>
                    <Tag color="error" icon={<ExclamationCircleOutlined />}>
                      Graphs may not be generated as the required values are missing. Scroll to <b>Missing Variables</b>{" "}
                      section for more info!
                    </Tag>
                  </div>
                ) : (
                  ""
                )}
                <Graph xAxisLabel={axesLabels.x} yAxisLabel={axesLabels.y} data={graphPlotData} />

                <div style={{ marginTop: 8 }}>
                  <Variables columns={propertyColumns} data={propertyData} />
                </div>
              </>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default MaterialComparison;
