import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Table, Select, message, Input, notification, Space } from "antd";
import { saveAs } from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { getTestMethodData, getTestMethodOptions, downloadSpecimenFile } from "../axios/index";
import PageHeader from "../components/header/pageHeader";

const { Option } = Select;
const { Search } = Input;
const DEFAULT_TEST_METHOD = "TTM-00199";

const MechanicalProperties = () => {
  const dispatch = useDispatch();

  const [testMethods, setTestMethods] = useState([]);
  const [selectedTestMethod, setSelectedTestMethod] = useState(DEFAULT_TEST_METHOD);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTestMethods = useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(getTestMethodOptions());
      setTestMethods(response.data || []);
    } catch (error) {
      message.error("Failed to fetch test methods");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await dispatch(getTestMethodData(selectedTestMethod));
      setAllData(response.data.responseData || []);
      setFilteredData(response.data.responseData || []);
    } catch (error) {
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  }, [dispatch, selectedTestMethod]);


  useEffect(() => {
    fetchTestMethods();
  }, [fetchTestMethods]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  const handleTestMethodChange = (value) => {
    setSelectedTestMethod(value);
  };

  const compressionColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 48,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 320,
    },
    {
      title: "Test Method",
      dataIndex: "testMethod",
      key: "testMethod",
    },
    {
      title: "RequestID",
      dataIndex: "requestId",
      key: "requestId",
      width: 140,
    },
    {
      title: "Sample ID",
      dataIndex: "sampleId",
      key: "sampleId",
      width: 140,
    },
    {
      title: "Compression Material Description",
      dataIndex: "materialDescription",
      key: "materialDescription",
      width: 320,
    },
    {
      title: "Requestor Name",
      dataIndex: "requestorName",
      key: "requestorName",
      width: 140,
    },
    {
      title: "Product Category",
      dataIndex: "productCategory",
      key: "productCategory",
      width: 140,
    },
    {
      title: "Material ID",
      dataIndex: "materialID",
      key: "materialID",
      width: 140,
      sorter: (a, b) => a.materialID - b.materialID,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 124,
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (text) => new Date(text).toLocaleDateString("en-US"),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="ghost"
              size="small"
              disabled={record?.rawJSONPath?.length === 0}
              onClick={() => downloadFile(record?.rawJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Raw JSON</span>
              </div>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={record?.finalJSONPath == null}
              onClick={() => downloadFile(record?.finalJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Finalized JSON</span>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const genericColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 48,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 320,
    },
    {
      title: "Test Method",
      dataIndex: "testMethod",
      key: "testMethod",
    },
    {
      title: "Material ID",
      dataIndex: "materialID",
      key: "materialID",
      width: 140,
      sorter: (a, b) => a.materialID - b.materialID,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 124,
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (text) => new Date(text).toLocaleDateString("en-US"),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      render: (text, record) => {
        // const showCsvButton = !(selectedTestMethod === 'STM-00437 Dry' || selectedTestMethod === 'STM-00437 Wet');
        return (
          <Space>
            <Button
              type="ghost"
              size="small"
              disabled={record?.csvFilePath?.length === 0}
              onClick={() => downloadFile(record?.csvFilePath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>CSV</span>
              </div>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={record?.rawJSONPath?.length === 0}
              onClick={() => downloadFile(record?.rawJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Raw JSON</span>
              </div>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={record?.finalJSONPath == null}
              onClick={() => downloadFile(record?.finalJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Finalized JSON</span>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const downloadFile = async (filePath) => {
    const key = `open${Date.now()}`;

    notification.open({
      message: "File download is in progress!",
      type: "info",
      key,
    });

    try {
      const response = await dispatch(downloadSpecimenFile(filePath));
      const fileExtension = filePath.split(".").pop();
      let mimeType = "application/octet-stream";

      if (fileExtension === "csv") {
        mimeType = "text/csv";
      } else if (fileExtension === "json") {
        mimeType = "application/json";
      }

      const blob = new Blob([response.data], { type: mimeType });
      saveAs(blob, filePath.split("/").pop());

      notification.close(key);
      notification.open({
        message: "File download completed successfully!",
        type: "success",
        duration: 2,
      });
    } catch (error) {
      console.error("Download error:", error);
      notification.close(key);
      notification.open({
        message: "File download failed!",
        type: "error",
        duration: 2,
      });
    }
  };

  const handleSearch = (value) => {
    const newValue = value.toLowerCase();

    let filtered;

    if (selectedTestMethod.startsWith("STM-00437")) {
      filtered = allData.filter(
        (item) =>
          item.name.includes(newValue) ||
          item.description.includes(newValue) ||
          item.requestId?.includes(newValue) ||
          item.sampleId?.includes(newValue) ||
          item.materialDescription?.includes(newValue) ||
          item.requestorName?.includes(newValue) ||
          item.productCategory?.includes(newValue) ||
          item.testState?.includes(newValue) ||
          item.createdBy.includes(newValue) ||
          item.materialID.toString().match(newValue)
      );
    } else {
      filtered = allData.filter(
        (item) =>
          item.name.includes(newValue) ||
          item.description.includes(newValue) ||
          item.testMethod?.includes(newValue) ||
          item.createdBy.includes(newValue) ||
          item.materialID.toString().match(newValue)
      );
    }

    setFilteredData(filtered);
  };

  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <div className="row">
          <PageHeader iconType={"compression"} title={"Mechanical Test Data"} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 8,
          }}
        >
          <Space align="baseline">
            <label htmlFor="test-methods-dd">Test Method: </label>
            <Select
              id="test-methods-dd"
              style={{ width: 340, marginBottom: 16 }}
              placeholder="Select Test Method"
              value={selectedTestMethod}
              onChange={handleTestMethodChange}
            >
              {Array.isArray(testMethods.responseData) &&
                testMethods.responseData.map((method) => (
                  <Option key={method.id} value={method.id} placeholder={method.id}>
                    {method.id} - {method.name}
                  </Option>
                ))}
            </Select>
          </Space>

          <Search placeholder="Search" onSearch={handleSearch} style={{ width: 340, marginBottom: 16 }} allowClear />
        </div>

        <Table
          columns={selectedTestMethod.startsWith("STM-00437") && !loading ? compressionColumns : genericColumns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          scroll={{ x: "max-content" }}
          pagination={{ hideOnSinglePage: true }}
        />
      </div>
    </div>
  );
};

export default MechanicalProperties;
