import React,  { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ReactDOM from 'react-dom';
import cloneDeep from 'lodash/cloneDeep';
import { INITIAL_VALUE, ALIGN_LEFT, ReactSVGPanZoom, TOOL_NONE, fitToViewer, ALIGN_BOTTOM } from 'react-svg-pan-zoom';
import useContextMenu from './MenuContextHook';
import { updateSvgPartByKey, updateSvgPartByMulitpleKeys, deleteLastAction, undoLastAction, deleteAllActions, updateAllSvgParts, updateSvgAssemblyParts, updateSvgSelectedMultipleLayers, updateLayerVisibilityForAreaSelection } from '../../axios/index'

import SvgCircle from './SvgCircle';
// import SvgZoom from "./SvgZoom"; 
import "./index.css";
import { Select } from "antd";
import Operation from "antd/lib/transfer/operation";
import { clone } from "@okta/okta-auth-js";
import IconSelectDrag from "../../icons/IconSelectDrag";
import AddConnectionLG from "../../icons/AddConnectionLG";
import RemovePointLG from "../../icons/RemovePointLG";
import AddMidPointLG from "../../icons/AddMidPointLG";
import RemoveConnectionLG from "../../icons/RemoveConnectionLG";
import AddPointLG from "../../icons/AddPointLG";
import MovePointLG from "../../icons/MovePointLG";
import RemoveHoleLG from "../../icons/RemoveHoleLG";

function SvgViewer(props) {
  const { configId, moduleName } = useParams();
  const Viewer = useRef(null);
  const dispatch = useDispatch();
  const svgSource = useSelector((state) => state.svgViewerData)
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [mousePos, setMousePos] = useState({});
  const [tool, setTool] = useState(TOOL_NONE)
  const [value, setValue] = useState(INITIAL_VALUE)
  const [svgWidthHeight, setSvgWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  const [svgConfigWidthHeight, setSvgConfigWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  
  const width = window.innerWidth - 280;
  const height = window.innerHeight - 150;
  const categories = useSelector((state) => state.partCategories)
  const selectedlayer = useSelector((state) => state.svgSelectedLayers)
  const svgLayerVisibility = useSelector((state)=>state.svgLayerVisibility.data)
  const parts = useSelector((state) => state.svgViewerPartData.data.configData.partData)
  const partsMetaData = useSelector((state) => state.svgViewerPartData.data.configData.metaData)
  const undoData = useSelector((state) => state.svgUndoData.data);
  const [partOrHole, setPartOrHole] = useState("");
  const [pointSelectionCount, setPointSelectionCount] = useState(0);
  const [startPoint, setStartPoint] = useState({x: null, y: null })
  const [endPoint, setEndPoint] = useState({x: null, y: null })
  const [areaSelection, setAreaSelection] = useState(false);
  console.log(" svgSource.data.assemblyData.data: ",  svgSource.data.assemblyData.data)
  console.log("undoData: ", undoData);
  console.log("***parts: ", parts);
  console.log("selectedlayer: ", selectedlayer);
  console.log("svgLayerVisibility: ", svgLayerVisibility);
 
  const [xCord, setXCord] = useState(0);
  const [yCord, setYCord] = useState(0);
  const [newXCord, setNewXCord] = useState(0);
  const [newYCord, setNewYCord] = useState(0);
  const [selectedPointType, setSelectedPointType] = useState("");
  const [newPointCords, setNewPointCords] = useState({x: null, y: null})
  const [sourcePoint, setSourcePoint] = useState({x: null, y: null})
  const [destinationPoint, setDestinationPoint] = useState({x: null, y: null})
  const [editOption, setEditOption] = useState("");
  const [currentHoleIndex, setCurretHoleIndex] = useState(null);
  const [rectangleCords, setRectangleCords] = useState({
    domainMinX: null,
    domainMinY: null,
    domainMaxX: null,
    domainMaxY: null
  })
  const [assemblyPartsRanges, setAssemblyPartsRanges] = useState([]);
  const [isAreaSelectionOn, setIsAreaSelectonOn] = useState(false);
  const [isUpdatePartData, setIsUpdatePartData] = useState(false);

  console.log("currentHoleIndex: ", currentHoleIndex);
  console.log("***editmode:", props.editMode)

  useEffect(() => {

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);


  useEffect(() => {

    if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
      let SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
      let SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
      setSvgWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    
    calculateRectangleCords();
    let assemblyParts = [...svgSource.data.assemblyData.data.assembly]
    if(assemblyParts.length > 0){
      let partsRanges = [];
      assemblyParts?.forEach((part) => {
        let range = calculateAssemblyPartsRanges(part.xy);
        partsRanges.push(range);
      });
      setAssemblyPartsRanges([...partsRanges]);
    }
  }, [svgSource, props.preview, moduleName]);



  useEffect(() => {
    if ((props.preview === false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')){
    
    if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
      let tempRanges = JSON.parse(partsMetaData.meshType)
      let SVGHeight = tempRanges.y.max - tempRanges.y.min;
      let SVGWidth = tempRanges.x.max - tempRanges.x.min;
      setSvgConfigWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    
  }

  }, [props.preview]);


  useEffect(()=>{
    setXCord(null);
    setYCord(null);
    setNewXCord(null);
    setNewYCord(null);
  }, [props.currentPartIndex])
  
  console.log("props.currentPartIndex:", props.currentPartIndex)
  useEffect(()=>{
    console.log("delete actions called")
    dispatch(deleteAllActions({}));
  }, [])

  useEffect(() => {
    if(props.editMode){
      createConnectionArray();
      createHoleConnectionArray();
      createCordPairs();
      createHoleCordPairs();
    }else{
      setPartOrHole("");
      setEditOption("");
    }
  }, [props.editMode]);

  useEffect(() => {
    if(!props.createPartStarted){
      createConnectionArray();
      createHoleConnectionArray();
      createCordPairs();
      createHoleCordPairs();
      setIsUpdatePartData(true);
    }
  }, [props.createPartStarted]);

  useEffect(()=>{
    if(!areaSelection && isAreaSelectionOn){
      highlightSelectedPart();
    }
  }, [areaSelection])

  useEffect(()=>{
    let tempPart  = cloneDeep(parts); 
    dispatch(updateAllSvgParts({ data: tempPart }))
  }, [svgSource])

  useEffect(()=>{
    let tempPart  = cloneDeep(parts); 
    if(tempPart[props.currentPartIndex]?.data?.partHoleReference.length === 0){
      setPartOrHole("part")
    }
    if(tempPart[props.currentPartIndex]?.data?.partHoleReference.length === 1){
      setCurretHoleIndex(0);
    }
  }, [parts, props.currentPartIndex, props.editMode])

  console.log("partOrHole: ", partOrHole)

  const getConvertedTransformedSVGPath = (coordinates) => {
    let newPath = "";
    let flatArray = coordinates.flat(Infinity);
    
    const extractCoordinates = (data) => {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (Array.isArray(data[i]) && data[i].length === 2 && typeof data[i][0] === 'number' && typeof data[i][1] === 'number') {
            // Handle the first point with 'M', the last point with 'Z', and others with space
            if (i === 0) {
              newPath += `M ${data[i][0]}, ${data[i][1]}`;
            } 
            // else if (i === data.length - 1) {
            //   newPath += ` ${data[i][0]}, ${data[i][1]}Z`;
            // } 
            else {
              newPath += ` ${data[i][0]}, ${data[i][1]}`;
            }
          } else if (Array.isArray(data[i])) {
            // Recursively handle nested arrays
            extractCoordinates(data[i]);
          }
        }
      }
    };
  
    extractCoordinates(coordinates);
    if(flatArray[0] === flatArray[flatArray.length-2] && flatArray[1] === flatArray[flatArray.length-1]){
      newPath = newPath + 'Z';
    }
    return newPath;
  };

  console.log("createPartStarted: ",props.createPartStarted)

  const updatePartsData = () => {
    let tempPart  = cloneDeep(parts);  
    let processedArray = []
    console.log("@@props.cordPairs: ", props.cordPairs)
    console.log("@@props.connectionArray: ", props.connectionArray)

    if(props.cordPairs.length > 0 && props.connectionArray.length > 0){
      processedArray = processPieces(props.cordPairs, props.connectionArray);
      setConnArrayUnUsed();
      console.log("processedArray: ", processedArray)
    }
   
    console.log("tempPart: ", tempPart)
    const partData = tempPart?.[props.currentPartIndex];
    if (partData) {
      console.log("inside if processedArray: ", processedArray)
      console.log("inside if connectionArray: ", props.connectionArray)
  
      if(props.cordPairs.length > 0 && props.connectionArray.length > 0){
        console.log("updating part")
        partData.data.partTransformedCoordinates = [...processedArray];
        partData.data.partTransformedSvgPath = getConvertedTransformedSVGPath(processedArray);
        dispatch(updateSvgPartByKey({ index: props.currentPartIndex, key: "partTransformedCoordinates", data: partData.data.partTransformedCoordinates }))
        dispatch(updateSvgPartByKey({ index: props.currentPartIndex, key: "partTransformedSvgPath", data: partData.data.partTransformedSvgPath }))
      }
      if(partData?.data?.partHoleReference?.length > 0){
        let deletedHoleIndexes = [];
        partData?.data?.partHoleReference?.forEach((holeData, index)=>{
          if(props.holeConnectionArray[index].length === 0 && props.holeCordPairs[index].length === 0){
            deletedHoleIndexes.push(index);
          }else{
            let processedArrayForHole = processPieces(props.holeCordPairs[index], props.holeConnectionArray[index])
            holeData.partTransformedCoordinates = [...processedArrayForHole];
            holeData.partTransformedSvgPath = getConvertedTransformedSVGPath(holeData.partTransformedCoordinates);
            setConnArrayUnUsedForHole(props.holeConnectionArray[index], index)
          }
        })
        const dataAfterHoleDeletion = partData?.data?.partHoleReference?.filter((hole, index)=> !deletedHoleIndexes.includes(index))
        tempPart[props.currentPartIndex].data.partHoleReference = [...dataAfterHoleDeletion];
        dispatch(updateSvgPartByKey({ index: props.currentPartIndex, key: "partHoleReference", data: tempPart[props.currentPartIndex].data.partHoleReference }))
      }
    }
    dispatch(deleteAllActions({}));
  }

  useEffect(()=>{
    if(!props.editMode){
      updatePartsData();
    }
  },[props.editMode])

  useEffect(()=>{
    if(isUpdatePartData){
      updatePartsData();
      setIsUpdatePartData(false);
    }
  },[props.connectionArray, props.cordPairs])

  const calculateAssemblyPartsRanges = (partCoordinates) => {
  
    function flattenArray(arr) {
      return arr?.reduce(function(acc, val) {
          return acc.concat(Array.isArray(val) ? flattenArray(val) : val);
      }, []);
    }
  
    let flattenedArray = flattenArray(partCoordinates);
    
    const groupedCoordinates = [];
  
    // Loop through the flat array, grouping every 2 values into [x, y] pairs
    for (let i = 0; i < flattenedArray.length; i += 2) {
        const x = flattenedArray[i];
        const y = flattenedArray[i + 1];
        groupedCoordinates.push([x, y]);
    }
  
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    
    groupedCoordinates?.forEach((coordSet, index) => {
  
      let [x, y] = coordSet;
      // Update minimum and maximum values
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });
  
    return(
      {
        "x": {
        "min": minX,
        "max": maxX
        },
        "y":{
          "min": minY,
          "max": maxY
        }
      }
    )
  } 

  console.log("assemblyPartsRanges: ", assemblyPartsRanges)

  const calculateRectangleCords = () => {
    console.log("calculateRectangleCords called");
    let rangeData = {};
    if((props.preview===true && moduleName === 'config-viewer') 
        || (props.preview === false && moduleName === 'assembly-viewer')){

          rangeData = svgSource.data.assemblyData.data.ranges;
          console.log("for assembly file");

    }else if((props.preview === true && moduleName === 'assembly-viewer') 
              || (props.preview === false && moduleName === 'config-viewer')){

          console.log("for config file");
          console.log("***partsMetaData: ", partsMetaData)
          rangeData = partsMetaData?.ranges;
    }
    console.log("rangeData: ", rangeData)
    if(rangeData){
      let {x, y} = rangeData;
      let xRange = x.max - x.min;
      let yRange = y.max - y.min;
      let twentyPercentOfRangeX = 0.2 * xRange;
      let twentyPercentOfRangeY = 0.2 * yRange;
      let frameWidth = twentyPercentOfRangeX > twentyPercentOfRangeY ? twentyPercentOfRangeX : twentyPercentOfRangeY;
      let domainMinX = x.min - frameWidth;
      let domainMinY = y.min - frameWidth;
      let domainMaxX = x.max + frameWidth;
      let domainMaxY = y.max + frameWidth;
      setRectangleCords({
        domainMinX: domainMinX,
        domainMinY: domainMinY,
        domainMaxX: domainMaxX,
        domainMaxY: domainMaxY
      })
    }
   
  }

  console.log("rectangleCords: ", rectangleCords)
  // const getSvgPathForHoles = () => {
  //   let holeSvgPaths = [];
  //   let tempPart = cloneDeep(parts);
  //   tempPart[props.currentPartIndex]?.data?.partHoleReference?.forEach((hole, index)=>{
  //     holeSvgPaths[index] = hole.partTransformedSvgPath;
  //   })
  //   props.setHoleSvgPathAfterEdit([...holeSvgPaths]);
  // }

  const createConnectionArray = () => {
    console.log("parts from con array:", parts)
    let tempPart  = cloneDeep(parts);  
    const connectionPairs = getConnectionArray(tempPart[props.currentPartIndex]?.data?.partTransformedCoordinates);
    props.setConnectionArray([...connectionPairs]);
  }

  const createHoleConnectionArray = () => {
    let tempPart  = cloneDeep(parts);  
    const allHoleCords = tempPart[props.currentPartIndex]?.data?.partHoleReference.map(hole => hole?.partTransformedCoordinates);
    
    let connectionPairs = [] 
    allHoleCords?.forEach((cords)=>{
      let holeConnections = getConnectionArray(cords);
      connectionPairs.push(holeConnections);
    })
    
    console.log("allHoleCords: ", allHoleCords)
    props.setHoleConnectionArray([...connectionPairs]);
  }

  const highlightSelectedPart = () => {
    console.log("highlightSelectedPart called");

    // Check if start and end points are defined
    if (startPoint.x !== null && startPoint.y !== null && endPoint.x !== null && endPoint.y !== null) {
        console.log("123startPoint: ", startPoint);
        console.log("123endPoint: ", endPoint);
        console.log("123assemblyPartsRanges:", assemblyPartsRanges);
        
        let overlappingIndices = [];
        assemblyPartsRanges?.forEach((part, index) => {
            // Calculate adjusted Y values
            const startYAdjusted = startPoint.y * -1;
            const endYAdjusted = endPoint.y * -1;
            const minX = startPoint.x < endPoint.x ? startPoint.x : endPoint.x;
            const minY = startYAdjusted < endYAdjusted ? startYAdjusted : endYAdjusted;
            const maxX = endPoint.x > startPoint.x ? endPoint.x : startPoint.x;
            const maxY = endYAdjusted > startYAdjusted ? endYAdjusted : startYAdjusted;

            // Log calculated values for debugging
            console.log(`Checking part index ${index}:`, part);
            console.log(`Start Y adjusted: ${startYAdjusted}, End Y adjusted: ${endYAdjusted}`);
            console.log(`Part Y range: Min: ${part.y.min}, Max: ${part.y.max}`);

            // Adjusted overlap conditions
            if (
              part.x.min >= minX && // Left edge check
              part.y.min >= minY && // Bottom edge check (adjusted)
              part.x.max <= maxX && // Right edge check
              part.y.max <= maxY  // Top edge check (adjusted)
            ) {
              console.log("123Part min max: ", part)
              console.log("123minX: ", minX)
              console.log("123minY: ", minY)
              console.log("123maxX: ", maxX)
              console.log("123maxY: ", maxY)
              console.log("123 check1: ",   part.x.min >= minX)
              console.log("123 check2: ",    part.y.min >= minY)
              console.log("123 check3: ",    part.x.max <= maxX)
              console.log("123 check4: ",    part.y.max <= maxY )
              
              

              overlappingIndices.push(index); // Add the index to the array if overlapping
            }
        }); // Start with an empty array

        if(overlappingIndices.length > 0){
          console.log("inside if overlappingIndices greater than 0")
          let layerData = {...selectedlayer.data}; 
          svgSource?.data?.assemblyData?.data?.assembly?.forEach((part, index)=>{
            if(overlappingIndices?.includes(index)){
              console.log("123part: ", part)
              let partName, parentIndex,childIndex, pathType; 
              partName = part.part;
              parentIndex = index;
              childIndex = -1;
              pathType = part.part     
              let visible = selectedlayer.data[partName] !== undefined ? !selectedlayer.data[partName]['visible'] : true; 
              layerData[partName] = {status: true, visible:visible, parentIndex: parentIndex, childIndex: childIndex}
            }
          })
          console.log("123layerData: ", layerData)
          dispatch(updateSvgSelectedMultipleLayers({data: layerData}))
        }
        console.log("Overlapping indices: ", overlappingIndices); 
        
    } else {
        console.log("One or more points are null");
    }
  }

  //console.log("123selectedLayers:", selectedlayer);


  const createCordPairs = () => {
    let tempPart  = cloneDeep(parts);  
    let coordinatePairs = getCoordinatePairsArray(tempPart[props.currentPartIndex]?.data?.partTransformedCoordinates);
    props.setCordPairs([...coordinatePairs]);
  }

  const createHoleCordPairs = () => {
    let tempPart  = cloneDeep(parts); 
    // let allHoleCords = [];
    let coordinatePairs = [];
    tempPart[props.currentPartIndex]?.data?.partHoleReference?.forEach((hole) =>{
     
        let holeCords = getCoordinatePairsArray(hole.partTransformedCoordinates);
        coordinatePairs.push(holeCords);
      
    });
   
    
    props.setHoleCordPairs([...coordinatePairs]);
  }

  console.log("Hole Connection Array: ", props.holeConnectionArray)
  console.log("Hole Cords Array: ", props.holeCordPairs)
  //console.log("Hole holeSVGPathAfterEdit: ", props.holeSvgPathAfterEdit)

  const getConnectionArray = (item) => {
    let connArray = [];
    let firstIndex = 0;
    const extractCoordinates = (data) => {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          extractCoordinates(data[i]);
          if(typeof data[0][0] === 'number'){
            if(data[0][0] == data[data.length-1][0] && data[0][1] == data[data.length-1][1] ){ 
                if(i <= data.length - 2 ){
                    if(i == data.length - 2){
                      connArray.push([i+firstIndex, firstIndex, 1])
                          firstIndex = firstIndex + data.length - 1
                    }else{
                      connArray.push([i+firstIndex, i+1+firstIndex, 1])
                    }
                }
            }else {
              if(i == data.length - 1){
                firstIndex = firstIndex + data.length
              }else{
                connArray.push([i+firstIndex, i+1+firstIndex, 1])
              }
            }
          }
        }
      } 
    };
  extractCoordinates(item);
   return connArray;
  };

  console.log("props.connectionArray: ", props.connectionArray)
  console.log("props.cordPairs: ", props.cordPairs)
  console.log("svgPathAfterEdit: ", props.svgPathAfterEdit)

  const centerAlignSvg = (e) => {
    let tempValue;
    if (e === 'default') {
      tempValue = cloneDeep(Viewer.current.getValue());
    }
    else {
      tempValue = cloneDeep(e);
    }
    if (props.preview === false && moduleName === 'config-viewer'){
      if (partsMetaData?.ranges !== undefined && partsMetaData?.ranges!=='' && partsMetaData?.ranges!== null) {
        console.log("partsMetaData?.meshType: ", partsMetaData?.meshType)
        console.log("partsMetaData: ", partsMetaData)
        let tempRanges = partsMetaData?.ranges
        tempValue.SVGHeight = tempRanges.y.max - tempRanges.y.min;
        tempValue.SVGWidth = tempRanges.x.max - tempRanges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = tempRanges.x.min;
        tempValue.SVGMinY = -Math.abs(tempRanges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    else if((props.preview === true && moduleName === 'assembly-viewer')){
      if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
        let tempRanges = JSON.parse(partsMetaData.meshType)
        tempValue.SVGHeight = tempRanges.y.max - tempRanges.y.min;
        tempValue.SVGWidth = tempRanges.x.max - tempRanges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = tempRanges.x.min;
        tempValue.SVGMinY = -Math.abs(tempRanges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    else{
      if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
        tempValue.SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
        tempValue.SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.SVGMinY = -Math.abs(svgSource.data.assemblyData.data.ranges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    //?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={props.preview===false && moduleName === 'config-viewer'?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}

    
  }

  const miniatureProps = { position: 'none' }

  const handelOriginPointRadius = () => {
    //--point-radius
    if (Viewer.current) {
     
      const viewerInstance = Viewer.current;
      const newRadius = 2 / viewerInstance.getValue().a;
      document.documentElement.style.setProperty('--point-radius', newRadius * 1.5);
      document.documentElement.style.setProperty('--point-stroke', newRadius * 1.5);
      console.log("viewerInstance.getValue():",viewerInstance.getValue())

    }
  };

  const handleMouseMove = (event) => {
    if(event.hasOwnProperty('SVGViewer') && areaSelection){
      console.log("mouse is moving: ", event, event.x, event.y)
      setEndPoint({x: event.x, y: event.y});
    }
   
  };

  const handleMouseDown = (event) => {
    console.log("mouse is Down: ", event);
    setAreaSelection(true);
    setEndPoint({x: null, y: null});
    setStartPoint({x: event.x, y: event.y});
    if(editOption === "add_point"){
      setNewPointCords({x: event.x, y: event.y * -1});
    }
  }

  console.log("startpoint: ", startPoint)
  console.log("endpoint: ", endPoint)

  const handleMouseUp = (event) => {
    console.log("mouse is up: ", event);
    setAreaSelection(false);
  }


  const handleClick = (event) => {
    // layer selection
    if(!isAreaSelectionOn){
      event.stopPropagation();
      let keyboardKey = 'none'
      if (event.originalEvent.ctrlKey) {
        keyboardKey = 'ctrlKey';
      }
      else if (event.originalEvent.altKey) {
        keyboardKey = 'altKey';
      }
      if (event.originalEvent.target.nodeName === 'path') {
  
        props.handlelayerSelection(event.originalEvent.target, 'svgViewer', keyboardKey)
  
  
      }
    }
   
  }

  const updateCoordinateEdit = (e, cord, pointType, index) => {
    console.log("updateCoordinateEdit: ", cord, pointType, index);
    setSelectedPointType(pointType);
    setXCord(Number(cord[0]));
    setYCord(Number(cord[1]));
    setNewXCord(Number(cord[0]));
    setNewYCord(Number(cord[1]));
    if(pointType === "hole"){
      setCurretHoleIndex(index);
    }
    if(editOption === "delete_point"){
      handleDeletePoint(pointType, Number(cord[0]), Number(cord[1]));
    }
    if(editOption === "create_connection" && pointSelectionCount === 0){
      setSourcePoint({x: Number(cord[0]), y: Number(cord[1])})
      setPointSelectionCount(pointSelectionCount + 1);
    }else if(editOption === "create_connection" && pointSelectionCount === 1){
      setDestinationPoint({x: Number(cord[0]), y: Number(cord[1])});
      setPointSelectionCount(0);
    }
   
  }

  const getCoordinatePairsArray = (coordinates) => {
    let coordinatePairs = [];
  
    // Recursive function to extract coordinates and create lines
    const extractCoordinates = (data) => {
      if (Array.isArray(data)) {
        let pairs = [];
  
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
  
          if (Array.isArray(item)) {
            if (item.length === 2 &&
                typeof item[0] === 'number' && typeof item[1] === 'number') {
              pairs.push(item); // Collect valid coordinate pairs
            } else {
              // Recursively handle nested arrays
              extractCoordinates(item);
            }
          }
        }
  
        // Handle exclusion of the last pair if it matches the first one
        if (pairs.length > 1) {
          const firstPair = pairs[0];
          const lastPair = pairs[pairs.length - 1];
          
          // Exclude last pair if it matches the first one
          if (JSON.stringify(firstPair) === JSON.stringify(lastPair)) {
            pairs.slice(0, -1).forEach(pair => coordinatePairs.push(pair));
          } else {
            pairs.forEach(pair => coordinatePairs.push(pair));
          }
        } else {
          // If there's only one or no valid pair, just add them
          pairs.forEach(pair => coordinatePairs.push(pair));
        }
      }
    };
  
    extractCoordinates(coordinates);
    console.log("coordinates: ", coordinates)
    return coordinatePairs;
  };


  function extractCoordinateGroups(arr) {
    const result = [];

    arr.forEach(item => {
        if (Array.isArray(item)) {
            // Create a new group for each nested array
            const group = extractCoordinatePairs(item);
            if (group.length > 0) {
                result.push(group);
            }
        }
    });

    return result;
}

function extractCoordinatePairs(arr) {
    const pairs = [];

    arr.forEach(item => {
        if (Array.isArray(item)) {
            // If it's a coordinate pair, push it to pairs
            if (item.length === 2) {
                pairs.push(item);
            } else {
                // Recursively handle deeper nesting
                pairs.push(...extractCoordinatePairs(item));
            }
        }
    });

    return pairs;
}

const getLinesWithArrows = (coordinatePairs) => {
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a || 1; // Default to 1 if `a` is undefined
  let allLines = [];

  // Function to compute mid-points
  const getMidPoint = (x1, y1, x2, y2) => ({
    xMid: (x1 + x2) / 2,
    yMid: (y1 + y2) / 2,
  });

  const coordinateGroups = extractCoordinateGroups(coordinatePairs);
  console.log("123coordinateGroups: ", coordinateGroups);

  allLines = coordinateGroups?.flatMap((group) => 
    group?.map((cords, j) => {
      if (j < group.length - 1) {
        const midPoints = getMidPoint(group[j][0], group[j][1], group[j + 1][0], group[j + 1][1]);
        return (
          <g key={`line-${group[j][0]}-${group[j][1]}-${group[j + 1][0]}-${group[j + 1][1]}`}>
            <defs>
              <marker
                id="arrowForLine"
                viewBox="0 0 10 10"
                refX="5"
                refY="5"
                markerWidth={factor + 5}
                markerHeight={factor + 5}
                stroke="grey"
                orient="auto-start-reverse"
                fill="#fff">
                <path d="M 0 0 L 10 5 L 0 10 z" />
              </marker>
            </defs>
            <line
              x1={group[j][0]}
              y1={group[j][1]}
              x2={midPoints.xMid}
              y2={midPoints.yMid}
              stroke="blue"
              strokeWidth={factor}
              markerEnd="url(#arrowForLine)" />
          </g>
        );
      }
      return null; // Return null if the condition is not met
    }).filter(Boolean) // Remove null values from the array
  );

  console.log("allLines:", allLines); // Debugging line

  return allLines; // Return the array of lines with arrows
};

  
// const getLinesWithArrows = (coordinatePairs) => {
//   const viewerInstance = Viewer?.current;
//   const factor = 2 / viewerInstance?.getValue().a || 1; // Default to 1 if `a` is undefined
//   let allLines = [];

//   // Function to compute mid-points
//   const getMidPoint = (x1, y1, x2, y2) => ({
//     xMid: (x1 + x2) / 2,
//     yMid: (y1 + y2) / 2,
//   });

//   const coordinateGroups = extractCoordinateGroups(coordinatePairs);
//   console.log("123coordinateGroups: ", coordinateGroups)

//   coordinateGroups?.forEach((group, i)=>{
//     //console.log("group: ", group)
//     group?.forEach((cords, j)=>{

//       if(j<group.length-1){

//         //console.log("cords: ", cords, group[j][0], group[j][1], group[j+1][0], group[j+1][1])
//         const midPoints = getMidPoint(group[j][0], group[j][1], group[j+1][0], group[j+1][1]);
      
//         allLines.push(
//           <g key={`line-${group[j][0]}-${group[j][1]}-${group[j+1][0]}-${group[j+1][1]}`}>
//             <defs>
//               <marker
//                 id="arrowForLine"
//                 viewBox="0 0 10 10"
//                 refX="5"
//                 refY="5"
//                 markerWidth={factor + 5}
//                 markerHeight={factor + 5}
//                 stroke="grey"
//                 orient="auto-start-reverse"
//                 fill="#fff">
//                 <path d="M 0 0 L 10 5 L 0 10 z" />
//               </marker>
//             </defs>
//             <line
//               x1={group[j][0]}
//               y1={group[j][1]}
//               x2={midPoints.xMid}
//               y2={midPoints.yMid}
//               stroke="blue"
//               strokeWidth={factor}
//               markerEnd="url(#arrowForLine)" />
//           </g>
//         );
//       }
//     })
//   })

//   console.log("allLines:", allLines); // Debugging line

//   return allLines; // Return the array of lines with arrows
// };

console.log("editOption: ", editOption);

const handleSelectionForPart = (e, partIndex, connection, x1, y1, x2, y2) => {

  console.log("current connection: ", connection, x1, y1, x2, y2)
  let tempPart  = cloneDeep(parts);    
  let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
  

  if(editOption === "delete_connection"){
    const updatedConnectionArray = props.connectionArray?.filter(
      (arr) => !arr.every((val, index) => val === connection[index])
    );
    console.log("updatedConnectionArray: ", updatedConnectionArray)
    props.setConnectionArray([...updatedConnectionArray]);
    dispatch(undoLastAction({ actionName: "delete_connection", coordinatePairs: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'part', connectionPairs: props.connectionArray }))
  
  }else if(editOption === "add_point_on_path"){
    let newPointIndex = props.cordPairs.length;
    let xMid = (x1 + x2) / 2;
    let yMid = (y1 + y2) / 2;
    setNewPointCords({x: xMid, y: yMid});
    let connectionPairs = [...props.connectionArray]
    const pairIndex = connectionPairs?.findIndex(pair => pair[0] === connection[0] && pair[1] === connection[1]);
    if (pairIndex !== -1) {
      connectionPairs.splice(pairIndex, 1);
    }
    dispatch(undoLastAction({ actionName: "add_point_on_path", coordinatePairs: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'part', connectionPairs: props.connectionArray }))
    let newCordPairsArray = [...props.cordPairs, [Number(xMid), Number(yMid)]];
    props.setCordPairs([...newCordPairsArray])
    let newConArray = [...connectionPairs, [connection[0], newPointIndex, 1], [newPointIndex, connection[1], 1]];
    props.setConnectionArray([...newConArray]);
  }
};


const handleSelectionForHole = (e, partIndex, holeIndex, connection, x1, y1, x2, y2) => {

  console.log("current connection: ", connection, x1, y1, x2, y2)
  let tempPart  = cloneDeep(parts);    
  let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
  

  if(editOption === "delete_connection"){
    const updatedConnectionArray = props.holeConnectionArray[holeIndex]?.filter(
      (arr) => !arr.every((val, index) => val === connection[index])
    );
    console.log("updatedConnectionArray: ", updatedConnectionArray)
    dispatch(undoLastAction({ actionName: "delete_connection", coordinatePairs: props.holeCordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'hole', connectionPairs: props.holeConnectionArray }))
    let holeConArray = [...props.holeConnectionArray];
    holeConArray[holeIndex] = [...updatedConnectionArray]
    props.setHoleConnectionArray([...holeConArray]);
   
  }else if(editOption === "add_point_on_path"){
    let newPointIndex = props.holeCordPairs[holeIndex].length;
    let xMid = (x1 + x2) / 2;
    let yMid = (y1 + y2) / 2;
    setNewPointCords({x: xMid, y: yMid});
    let connectionPairs = [...props.holeConnectionArray[holeIndex]]
    const pairIndex = connectionPairs?.findIndex(pair => pair[0] === connection[0] && pair[1] === connection[1]);
    if (pairIndex !== -1) {
      connectionPairs.splice(pairIndex, 1);
    }
    dispatch(undoLastAction({ actionName: "add_point_on_path", coordinatePairs: props.holeCordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'hole', connectionPairs: props.holeConnectionArray }))
    let allHoleCords = [...props.holeCordPairs];
    let newCordPairsArray = [...props.holeCordPairs[holeIndex], [Number(xMid), Number(yMid)]];
    allHoleCords[holeIndex] = [...newCordPairsArray]
    props.setHoleCordPairs([...allHoleCords])
    let allHoleConnections = [...props.holeConnectionArray]
    let newConArray = [...connectionPairs, [connection[0], newPointIndex, 1], [newPointIndex, connection[1], 1]];
    allHoleConnections[holeIndex] = [...newConArray]
    props.setHoleConnectionArray([...allHoleConnections]);
  }
};



const getLines = (partIndex) => {
  
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;
  let lines = [];

  props.connectionArray?.forEach((con, index)=>{
   
      let currentCordIndex = props.connectionArray[index][0];
      let nextCordIndex = props.connectionArray[index][1];
      let x1 = props.cordPairs[currentCordIndex][0];
      let y1 = props.cordPairs[currentCordIndex][1];
      let x2 = props.cordPairs[nextCordIndex][0];
      let y2 = props.cordPairs[nextCordIndex][1];

      lines.push( 
      <g className="editModeLines" key={con}>
        <path 
        cursor="pointer" 
        strokeWidth="2" 
        stroke-width={factor * 2} 
        onClick={(e)=>handleSelectionForPart(e, partIndex, con, x1, y1, x2, y2)}
        stroke="blue"
        d={`M ${x1}, ${y1} ${x2}, ${y2}`}/>
      </g>
      )
  })
  
  console.log("lines: ", lines)

  return lines;
}

const getLinesForHoles = (partIndex) => {
  
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;
  let lines = [];

  props.holeConnectionArray?.forEach((conArray, holeIndex)=>{
      conArray?.forEach((con, index)=>{
    
        let currentCordIndex = conArray[index][0];
        let nextCordIndex = conArray[index][1];
        let x1 = props.holeCordPairs[holeIndex][currentCordIndex][0];
        let y1 = props.holeCordPairs[holeIndex][currentCordIndex][1];
        let x2 = props.holeCordPairs[holeIndex][nextCordIndex][0];
        let y2 = props.holeCordPairs[holeIndex][nextCordIndex][1];
        lines.push( 
        <g className="editModeLines" key={`${holeIndex}-${con}`}>
          <path 
          cursor="pointer" 
          strokeWidth="2" 
          stroke-width={factor * 2} 
          onClick={(e)=>handleSelectionForHole(e, partIndex, holeIndex, con, x1, y1, x2, y2)}
          stroke="grey"
          d={`M ${x1}, ${y1} ${x2}, ${y2}`}/>
        </g>
        )
    })
  })
  
  console.log("lines for holes: ", lines)

  return lines;
}

const getOriginForConfig = () => {
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;

  return(
      <>
        {/* Horizontal Line */}
        <line x1={-8 * factor} y1="0" x2={8 * factor} y2="0" stroke="white" stroke-width={factor}/>
        {/* Vertical Line */}
        <line x1="0" y1={-8 * factor} x2="0" y2={8 * factor} stroke="white" stroke-width={factor}/>
      </>
  )
}

  const renderParts = () => {
    console.log("renderParts called: ", parts);
    console.log("props.showPoints: ", props.showPoints);

    const viewerInstance = Viewer?.current;
    const factor = 2 / viewerInstance?.getValue().a;
   
    if (parts.length > 0 && props.showPoints === false) {
    
      let showOrigin = false;
      return parts.map((part, index) => {
        let cls = 'part'
        if (part.data.partStatus) {
          cls = 'part selected'
        }
        if (part.data.partVisibility && part.action.delete === false) {
          let hole = '';
          let color = part.data.partType === '' ? 'rgb(9 109 217 / 63%)' : renderColor(part.data.partType);

          if (part.data.partHoleReference.length > 0) {
            part.data.partHoleReference.map((tempHole, tempHoleIndex) => {
              if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {
                hole = tempHoleIndex === 0 ? hole + tempHole.partSourceSvgPath : hole + 'z' + tempHole.partSourceSvgPath;
              }
              else {
                hole = tempHoleIndex === 0 ? hole + tempHole.partTransformedSvgPath : hole + 'z' + tempHole.partTransformedSvgPath;
              }

            })
          }
          if (part.data.partSourceSvgPath.trim() !== '') {
            let tempPath = '';
            if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {

              showOrigin = true;
              tempPath = part.data.partSourceSvgPath;
              console.log("***tempPath for Assembly: ", tempPath)
            }
            else {
              tempPath = part.data.partTransformedSvgPath;
            }
            if (hole.trim() !== '') {
              tempPath = tempPath + hole;
            }
            // else {
            //   tempPath = tempPath
            // }

            console.log("***tempPath: ", tempPath)
          
            return (
            <g className={cls} id='uio' fill={tempPath.includes('Z') ? color : ""} stroke='blue' cursor="default" pointerEvents="all" >
             {!props.editMode && <path data-pathType='part' data-parentIndex={index} data-childIndex={-1} vectorEffect="non-scaling-stroke" cursor="pointer" strokeWidth="2" d={tempPath} />}
              {showOrigin === true && part.data.partStatus === true &&
                <circle fill="#ff0000" stroke="none" cx={part.data.partCalculatedOrigin.x} cy={part.data.partCalculatedOrigin.y} r={factor * 1.5}>
                  <animate attributeName="opacity" dur="2s" values="0.5;1;0.5;" repeatCount="indefinite" begin="0.1" />
                </circle>
              }

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && !props.editMode
                && getLinesWithArrows(part.data.partTransformedCoordinates)
              }   

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && !props.editMode
                && part.data.partHoleReference?.map(hole => getLinesWithArrows(hole.partTransformedCoordinates))
              }    

              {/* Render lines for part in edit mode */}
              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && props.editMode
                && getLines(index)
              } 

              {/* Render lines for wholes in edit mode */}
              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && props.editMode
                && part.data.partHoleReference.length > 0
                && getLinesForHoles(index)
              } 

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && partOrHole === "part"
                && props.editMode
                && props.cordPairs?.map((cord, i)=>
                  <circle 
                  key={`point-${i}`} //Added key prop for React lists
                  data-type='point' 
                  className='configPoint' 
                  onClick={(e) => updateCoordinateEdit(e, cord, "part", i)} 
                  cx={cord[0]} 
                  cy={cord[1]} 
                  stroke={(cord[0] == xCord && cord[1] == yCord) ? "yellow" : "red"} 
                  fill={(cord[0] == xCord && cord[1] == yCord) ? "yellow" : "red"} 
                  stroke-width="1"
                  // r="0.5" 
                  />
              )}

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && part.data.partHoleReference.length > 0 
                && props.editMode
                && partOrHole === "hole"
                && currentHoleIndex === null
                && props.holeCordPairs?.map((hole, holeIndex)=>
                    hole.map((cord, i) => (
                        <circle
                          key={`point-${i}`} //Added key prop for React lists
                          data-type='point'
                          className='configPoint'
                          onClick={(e) => updateCoordinateEdit(e, cord, "hole", holeIndex)}
                          cx={cord[0]}
                          cy={cord[1]}
                          stroke={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                          fill={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                          stroke-width="1" // Changed to camelCase for strokeWidth
                          // r="0.5"
                        />
                    )))
              }

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && part.data.partStatus
                  && part.data.partHoleReference.length > 0 
                  && props.editMode
                  && partOrHole === "hole"
                  && currentHoleIndex !== null
                  && props.holeCordPairs[currentHoleIndex]?.map((cord, i) => (
                          <circle
                            key={`point-${i}`} //Added key prop for React lists
                            data-type='point'
                            className='configPoint'
                            onClick={(e) => updateCoordinateEdit(e, cord, "hole", currentHoleIndex)}
                            cx={cord[0]}
                            cy={cord[1]}
                            stroke={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                            fill={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                            stroke-width="1" // Changed to camelCase for strokeWidth
                            // r="0.5"
                          />
                  ))
                }
            </g>)
          }
        }
      })
    }
  }
  const handelOriginSelection = (e, index, selText, type) => {
    if (type === 'reset') {
      let newdataSet = {
        partOriginMetaData: [],
        partCalculatedOrigin: { x: 0, y: 0 }
      }
      dispatch(updateSvgPartByMulitpleKeys({ index: index, data: newdataSet }))
    }
    else {
      props.setPoints(e, index, selText)
    }
  }
  const renderCategeories = () => {
    if (categories.loading === false && categories.data.length > 0) {
      return categories.data.map((item, index) => {
        return (
          <option key={item.id} value={item.id}>{item.name}</option>
        )
      })
    }

  }
  const handelPartSelections = (part, index, key, val) => {
   // handelPartSelections(part, index, 'partType', e.target.value)}
    if (key === 'partDirection') {
      props.reCalulateTransformation(index, val, key)
    }
    else {
      dispatch(updateSvgPartByKey({ index: index, key: key, data: val }))
    }
  }
  const renderColor = (type) => {
    let color = '(9 109 217 / 63%)'
    if (categories.loading === false && categories.data.length > 0) {
      categories.data.map((item, index) => {
        if (type == item.id) {

          color = item.color
        }
      })
    }
    color = 'rgb' + color;
    return color;
  }

  const handleChangeXcord = (e) => {
    setNewXCord(e.target.value);
  }

  const handleChangeYcord = (e) => {
    setNewYCord(e.target.value);
  }

  const replaceMatchingPairs = (cords) => {
    // Iterate through the array to find and replace matching pairs
    return cords?.map(pair => {
        // Check if the current pair matches the oldPair
        if (pair[0] === Number(xCord) && pair[1] === Number(yCord)) {
            // Replace with newPair
            return [Number(newXCord), Number(newYCord)];
        }
        return pair;
    });
  }

  const handleUpdatePoint = (e, partIndex) => {
    let tempPart  = cloneDeep(parts);

    if(selectedPointType === "part"){
      let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
      const upadatedCoordinates = replaceMatchingPairs(props.cordPairs);
      dispatch(undoLastAction({ actionName: "update_point", coordinatePairs: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connectionPairs: props.connectionArray }))
      props.setCordPairs([...upadatedCoordinates]);
      setXCord(Number(newXCord));
      setYCord(Number(newYCord));
      //calculateSVGPathAfterEditForPart(props.connectionArray, upadatedCoordinates);
      

    }else if(selectedPointType === "hole"){
      let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
      dispatch(undoLastAction({ actionName: "update_point", coordinatePairs: props.holeCordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connectionPairs: props.holeConnectionArray }))
      const upadatedCoordinates = replaceMatchingPairs(props.holeCordPairs[currentHoleIndex]);
      let allHoleCords = [...props.holeCordPairs]
      allHoleCords[currentHoleIndex] = [...upadatedCoordinates]
      props.setHoleCordPairs([...allHoleCords]);
      setXCord(Number(newXCord));
      setYCord(Number(newYCord));
     // calculateSVGPathAfterEditForHole(props.connectionArray, allHoleCords);
      //calculateSVGPathAfterEditForPart(props.connectionArray, props.cordPairs);
    }   
  }

  const replaceWithEmptyArrayForPart = (xCoordinate, yCoordinate) => {
    if (!props.cordPairs) {
        return [];
    }
    // Use map to iterate over the array and replace matching pairs with an empty array
    return props.cordPairs.map(pair => {
        if (pair[0] === xCoordinate && pair[1] === yCoordinate) {
            // Replace matching pair with an empty array
            return [];
        }
        // Keep the original pair if it doesn't match
        return pair;
    });
  };

  const replaceWithEmptyArrayForHole = (xCoordinate, yCoordinate) => {
    if (!props.holeCordPairs[currentHoleIndex]) {
        return [];
    }
    // Use map to iterate over the array and replace matching pairs with an empty array
    return props.holeCordPairs[currentHoleIndex]?.map(pair => {
        if (pair[0] === xCoordinate && pair[1] === yCoordinate) {
            // Replace matching pair with an empty array
            return [];
        }
        // Keep the original pair if it doesn't match
        return pair;
    });
  };

  const handleDeletePoint = (pointType, xCoordinate, yCoordinate) => {

    let tempPart  = cloneDeep(parts);
    console.log("pointType: ", pointType, xCoordinate, yCoordinate )

    if(pointType === "part"){
      let currentSvgPath =  tempPart[props.currentPartIndex].data.partTransformedSvgPath;
      const pairsAfterDelete = replaceWithEmptyArrayForPart( xCoordinate, yCoordinate);
      dispatch(undoLastAction({ actionName: "delete_point", coordinatePairs: props.cordPairs, partIndex: props.currentPartIndex, svgPath: currentSvgPath, pointType: pointType, connectionPairs: props.connectionArray }))
      const indexOfPair = props.cordPairs?.findIndex(pair => pair[0] === xCoordinate && pair[1] === yCoordinate)
      const updatedConnectionArray = props.connectionArray?.filter(con => con[0] !== indexOfPair && con[1] !== indexOfPair)
      props.setConnectionArray([...updatedConnectionArray]);
      props.setCordPairs([...pairsAfterDelete]);
      //calculateSVGPathAfterEditForPart(updatedConnectionArray, pairsAfterDelete);

    }else if(pointType === "hole"){
      let currentSvgPath =  tempPart[props.currentPartIndex].data.partTransformedSvgPath;
      
      dispatch(undoLastAction({ actionName: "delete_point", coordinatePairs: props.holeCordPairs, partIndex: props.currentPartIndex, svgPath: currentSvgPath, pointType: pointType, connectionPairs: props.holeConnectionArray }))
      const indexOfPair = props.holeCordPairs[currentHoleIndex]?.findIndex(pair => pair[0] === xCoordinate && pair[1] === yCoordinate)
      const updatedConnectionArray = props.holeConnectionArray[currentHoleIndex]?.filter(con => con[0] !== indexOfPair && con[1] !== indexOfPair)
      let allConArray = [...props.holeConnectionArray]
      allConArray[currentHoleIndex] = [...updatedConnectionArray]
      props.setHoleConnectionArray([...allConArray]);
      let allHoleCordsPairs = [...props.holeCordPairs];
      const pairsAfterDelete = replaceWithEmptyArrayForHole(xCoordinate, yCoordinate);
      console.log("pairsAfterDelete: ", pairsAfterDelete)
      allHoleCordsPairs[currentHoleIndex] = [...pairsAfterDelete];
      props.setHoleCordPairs([...allHoleCordsPairs]);
     // calculateSVGPathAfterEditForHole(allConArray, allHoleCordsPairs);
      //calculateSVGPathAfterEditForPart(props.connectionArray, props.cordPairs);
    }
    setXCord(null);
    setYCord(null);
    setNewXCord(null);
    setNewYCord(null);
  }

  const handleUndo = () => {
    if(undoData.length > 0){
      let { actionName, coordinatePairs, connectionPairs, pointType } = undoData[undoData.length - 1];
      if(actionName === "add_point_on_path"){
        setNewPointCords({x: null, y: null});
      }
      if(pointType === "part"){
        props.setConnectionArray([...connectionPairs]);
        props.setCordPairs([...coordinatePairs]);
   
      }else if(pointType === "hole"){
        props.setHoleConnectionArray([...connectionPairs])
        props.setHoleCordPairs([...coordinatePairs])
      }
      dispatch(deleteLastAction({}));
      setXCord(null);
      setYCord(null);
      setNewXCord(null);
      setNewYCord(null);
    }
  }

  const coordinateExists = (data, targetX, targetY) => {
    console.log("coordinateExists params: ",data, targetX, targetY )
    const coordinateExistsHelper = (item) => {
      if (Array.isArray(item)) {
        // Check if the array has exactly two numeric elements (coordinate pair)
        if (item.length === 2 && typeof item[0] === 'number' && typeof item[1] === 'number') {
          // Check if this is the target coordinate pair
          if (item[0] === targetX && item[1] === targetY) {
            // Coordinate pair found
            return true;
          }
        } else {
          // Recursively check nested arrays
          for (let subitem of item) {
            if (coordinateExistsHelper(subitem)) {
              return true;
            }
          }
        }
      }
      // Coordinate pair not found in this path
      return false;
    };
  
    // Call the helper function to check if coordinate exists in data
    return coordinateExistsHelper(data);
  };

  const hideSelectedParts = () => {
    let selectedPartNames = Object.keys(selectedlayer.data);
    let hideParts = {};
    svgSource?.data?.assemblyData?.data?.assembly?.forEach((part, index)=>{
      if(selectedPartNames?.includes(part.part)){
        hideParts[part.part] = false;
      }
    })
    console.log("hideParts: ", hideParts)
    dispatch(updateLayerVisibilityForAreaSelection({data: hideParts}));
  }

  const hideNotSelectedParts = () => {
    let selectedPartNames = Object.keys(selectedlayer.data);
    let notSelectedParts = {};
    svgSource?.data?.assemblyData?.data?.assembly?.forEach((part, index)=>{
      if(!selectedPartNames?.includes(part.part)){
        notSelectedParts[part.part] = false;
      }
    })
    console.log("notSelectedParts: ", notSelectedParts)
    dispatch(updateLayerVisibilityForAreaSelection({data: notSelectedParts}));
  }
  

  const handleDeleteHole = () => {
    dispatch(undoLastAction({ actionName: "delete_hole", coordinatePairs: props.holeCordPairs, partIndex: props.currentPartIndex, pointType: partOrHole, connectionPairs: props.holeConnectionArray }));
    let allHoleConArray = [...props.holeConnectionArray];
    let allHoleCords = [...props.holeCordPairs];
    //let holeSvgPaths = [...props.holeSvgPathAfterEdit];

    allHoleConArray[currentHoleIndex] = [];
    allHoleCords[currentHoleIndex] = []
    //holeSvgPaths[currentHoleIndex] = "";

    props.setHoleConnectionArray([...allHoleConArray]);
    props.setHoleCordPairs([...allHoleCords]);
   // props.setHoleSvgPathAfterEdit([...holeSvgPaths]);
    setSelectedPointType("");
    setNewXCord(null);
    setNewYCord(null);
    setXCord(null);
    setYCord(null);
    setCurretHoleIndex(null);

    
  }

  const roundDecimals = (value) => {
    let data = value;
    // Rounding to 5 decimal places
    const roundedValue = Math.round(data * 100000) / 100000;
    return roundedValue;
  };

  const resetOriginSelection = (e, index, selText) => {
    if(undoData.length > 0){
      const message = "Changing the origin will result in the loss of any changes made to the part."
      // eslint-disable-next-line no-restricted-globals
      if (confirm(message) == true) {
        handelOriginSelection(e, index, selText, 'reset')
      }
    }else{
      handelOriginSelection(e, index, selText, 'reset')
    }
  
  }

  const handleChangeNewPointXCords = (e) => {
    setNewPointCords({...newPointCords, x: e.target.value} )
  }

  const handleChangeNewPointYCords = (e) => {
    setNewPointCords({...newPointCords, y: e.target.value} )
  }

  const handelEditOption = (e, actionName) => {
    if(actionName === editOption){
      setEditOption("");
    }else{
      setEditOption(actionName);
    }
  }

  const handleSourcePointX = (e) => {
    setSourcePoint({...sourcePoint, x: e.target.value})
  }
  
  const handleSourcePointY = (e) => {
    setSourcePoint({...sourcePoint, Y: e.target.value})
  }

  const handleDestinationPointX = (e) => {
    setDestinationPoint({...destinationPoint, x: e.target.value})
  }

  const handleDestinationPointY = (e) => {
    setDestinationPoint({...destinationPoint, Y: e.target.value})
  }

  const handleCreateConnection = () => {

    if(partOrHole === "part"){  
      const sourcePointIndex = props.cordPairs?.findIndex(item => 
        item[0] === sourcePoint.x && item[1] === sourcePoint.y
      );
      const destinationPointIndex = props.cordPairs?.findIndex(item => 
        item[0] === destinationPoint.x && item[1] === destinationPoint.y
      );
      
      dispatch(undoLastAction({ actionName: "create_connection", coordinatePairs: props.cordPairs, partIndex: props.currentPartIndex, svgPath: "", pointType: selectedPointType, connectionPairs: props.connectionArray }))
      let newConArray = [...props.connectionArray, [sourcePointIndex, destinationPointIndex, 1]];
      props.setConnectionArray([...newConArray]);
      //calculateSVGPathAfterEditForPart(newConArray, props.cordPairs);

    }else if(partOrHole === "hole"){
      const sourcePointIndex = props.holeCordPairs[currentHoleIndex]?.findIndex(item => 
        item[0] === sourcePoint.x && item[1] === sourcePoint.y
      );
      const destinationPointIndex = props.holeCordPairs[currentHoleIndex]?.findIndex(item => 
        item[0] === destinationPoint.x && item[1] === destinationPoint.y
      );
      
      dispatch(undoLastAction({ actionName: "create_connection", coordinatePairs: props.holeCordPairs, partIndex: props.currentPartIndex, svgPath: "", pointType: selectedPointType, connectionPairs: props.holeConnectionArray }))
      let allHoleConnections = [...props.holeConnectionArray]
      let currentHoleConnections = [...props.holeConnectionArray[currentHoleIndex]]
      let newCurrentHoleConnections = [...currentHoleConnections, [sourcePointIndex, destinationPointIndex, 1]];
      allHoleConnections[currentHoleIndex] = [...newCurrentHoleConnections]
      props.setHoleConnectionArray([...allHoleConnections]);
      //calculateSVGPathAfterEditForHole(allHoleConnections, props.holeCordPairs);
      //calculateSVGPathAfterEditForPart(props.connectionArray, props.cordPairs);
    }
    setSourcePoint({x: null, y: null});
    setDestinationPoint({x: null, y: null});
  }

  const handleAddNewPoint = (e, partIndex) => {
    if(partOrHole === "part"){
      dispatch(undoLastAction({ actionName: "add_point", coordinatePairs: props.cordPairs, partIndex: partIndex, svgPath: "", pointType: 'part', connectionPairs: props.connectionArray }))
      let newCordPairsArray = [...props.cordPairs, [Number(newPointCords.x), Number(newPointCords.y)]]
      props.setCordPairs([...newCordPairsArray])
      //calculateSVGPathAfterEditForPart(props.connectionArray, newCordPairsArray);

    }else if(partOrHole === "hole"){
      dispatch(undoLastAction({ actionName: "add_point", coordinatePairs: props.holeCordPairs, partIndex: partIndex, svgPath: "", pointType: 'hole', connectionPairs: props.holeConnectionArray }))
      let allHoleCords = [...props.holeCordPairs];
      let newCordPairsArray = [...props.holeCordPairs[currentHoleIndex], [Number(newPointCords.x), Number(newPointCords.y)]];
      allHoleCords[currentHoleIndex] = [...newCordPairsArray]
      props.setHoleCordPairs([...allHoleCords])
      //calculateSVGPathAfterEditForHole(props.holeConnectionArray, allHoleCords);
      //calculateSVGPathAfterEditForPart(props.connectionArray, props.cordPairs);
    }
    setNewPointCords({x: null, y: null});
   
  }

  const handelPartOrHole = (e) => {
    setPartOrHole(e.target.value);
    if(e.target.value === 'hole'){
      setCurretHoleIndex(null);
    }
  }

  // Define the functions and data

function unusedIndex(data) {
  for (let i = 0; i < data.length; i++) {
      if (data[i][2] === 1) {
          return i;
      }
  }
  return -1;
}

function hasConnection(indx, con, data) {
  for (let i = 0; i < data.length; i++) {
      if (data[i][2] === 1 && i !== con) {
          if (data[i][0] === indx) {
              return [i, 0];
          }
          if (data[i][1] === indx) {
              return [i, 1];
          }
      }
  }
  return [-1, -1];
}

// let pointArray = [
//   [-10, 0], [15, 0], [0, 0], [10, 0], [10, 10],
//   [5, 15], [0, 10], [4, 0], [6, 0], [6, 3],
//   [4, 3], [-5, 0], [-4, 8], [-3, 0]
// ];

// let connectionArray = [
//   [0, 1, 1], [3, 2, 1], [3, 4, 1], [4, 5, 1],
//   [5, 6, 1], [6, 2, 1], [7, 8, 1], [8, 9, 1],
//   [9, 10, 1], [10, 7, 1], [11, 12, 1], [12, 13, 1]
// ];

const setConnArrayUnUsed = () => {
 // Create a shallow copy of the connectionArray
let connections = [...props.connectionArray];

// Iterate over each connection and update the second index to 1
connections.forEach(con => {
  if (Array.isArray(con) && con.length === 3) {
    con[2] = 1;
  }
});

// Log the updated array to the console
console.log("**updatedArray: ", connections);

// Update the state with the new array
props.setConnectionArray(connections);
}

const setConnArrayUnUsedForHole = (conArray, holeIndex) => {
  // Create a shallow copy of the connectionArray
 let connections = [...conArray];
 
 // Iterate over each connection and update the second index to 1
 connections?.forEach(con => {
   if (Array.isArray(con) && con.length === 3) {
     con[2] = 1;
   }
 });
 
 // Log the updated array to the console
 console.log("**updatedArray: ", connections);
 let holeConArray = [...props.holeConnectionArray];
 holeConArray[holeIndex] = [...connections];
 // Update the state with the new array
 props.setHoleConnectionArray([...holeConArray]);
}

function processPieces(cordPairs, conArr) {
  let transformedArray = [];
  let piece = [];
  let pointArray = [...cordPairs];
  let connArray = [...conArr];

  console.log("new pointArray: ",pointArray)
  console.log("new connectionArray: ",connArray)
 
  let i1, j1, i2, j2, searchIndex, pieceStartIndex;
  
  [i1, j1] = hasConnection(connArray[0][0], 0, connArray);
  [i2, j2] = hasConnection(connArray[0][1], 0, connArray);
  console.log("i1, i2, j1, j2: ",i1, i2, j1, j2);
  

  if (i2 !== -1 && j2 !== -1) {
      piece.push(pointArray[connArray[0][0]]);
      piece.push(pointArray[connArray[0][1]]);
      searchIndex = connArray[0][1];
      connArray[0][2] = 0;
      pieceStartIndex = connArray[0][0];
  } else if (i1 !== -1 && j1 !== -1) {
      piece.push(pointArray[connArray[0][1]]);
      piece.push(pointArray[connArray[0][0]]);
      searchIndex = connArray[0][0];
      connArray[0][2] = 0;
      pieceStartIndex = connArray[0][1];
  } else {
    
      piece.push(pointArray[connArray[0][0]]);
      piece.push(pointArray[connArray[0][1]]);
      searchIndex = connArray[0][0];
      connArray[0][2] = 0;
      pieceStartIndex = connArray[0][1];
      
  }
  console.log("outside while loop");
  let doneConversion = false;

  while (unusedIndex(connArray) > -1) {
    console.log("inside while loop");
      let didFind = false;
      let pieceFinished = false;

      for (let index = 0; index < connArray.length; index++) {
          let connection = connArray[index];

          if (connection[2] === 1) {
              if (connection[0] === searchIndex) {
                  piece.push(pointArray[connection[1]]);
                  connArray[index][2] = 0;
                  searchIndex = connection[1];
                  didFind = true;
                  if (searchIndex === pieceStartIndex) pieceFinished = true;
              } else if (connection[1] === searchIndex) {
                  piece.push(pointArray[connection[0]]);
                  connArray[index][2] = 0;
                  searchIndex = connection[0];
                  didFind = true;
                  if (searchIndex === pieceStartIndex) pieceFinished = true;
              }
          }
      }

      if (didFind && pieceFinished) {
          transformedArray.push(piece);
          piece = [];
          let nextConnection = unusedIndex(connArray);

          if (nextConnection > -1) {
              [i1, j1] = hasConnection(connArray[nextConnection][0], nextConnection, connArray);
              [i2, j2] = hasConnection(connArray[nextConnection][1], nextConnection, connArray);

              if (i2 !== -1 && j2 !== -1) {
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  searchIndex = connArray[nextConnection][1];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][0];
              } else if (i1 !== -1 && j1 !== -1) {
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  searchIndex = connArray[nextConnection][0];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][1];
              } else {
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  searchIndex = connArray[nextConnection][0];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][1];
              }
          } else {
              doneConversion = true;
          }
          console.log("piece: ", piece)
      } else if (!didFind) {
          transformedArray.push(piece);
          piece = [];
          let nextConnection = unusedIndex(connArray);

          if (nextConnection > -1) {
              [i1, j1] = hasConnection(connArray[nextConnection][0], nextConnection, connArray);
              [i2, j2] = hasConnection(connArray[nextConnection][1], nextConnection, connArray);

              if (i2 !== -1 && j2 !== -1) {
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  searchIndex = connArray[nextConnection][1];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][0];
              } else if (i1 !== -1 && j1 !== -1) {
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  searchIndex = connArray[nextConnection][0];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][1];
              } else {
                  piece.push(pointArray[connArray[nextConnection][0]]);
                  piece.push(pointArray[connArray[nextConnection][1]]);
                  searchIndex = connArray[nextConnection][0];
                  connArray[nextConnection][2] = 0;
                  pieceStartIndex = connArray[nextConnection][1];
              }
          } else {
              doneConversion = true;
          }
          console.log("piece: ", piece)
      } else if (didFind && unusedIndex(connArray) === -1) {
          transformedArray.push(piece);
          piece = [];
          console.log("piece: ", piece)
      }
  }

  if (doneConversion == false){
    transformedArray.push(piece);
  }
   
  //setConnectionArray(connectionArray);
  console.log("new connectionArray : ", connArray);
  console.log("new final output: ", transformedArray);
  return transformedArray;
}

  
  const renderMetaDataPropertyPane = () => {
    console.log("renderMetaDataPropertyPane parts: ", parts)
    if (parts.length > 0) {
      let selText = props.showPoints === true ? 'Set' : 'Select'
      return parts.map((part, index) => {
        if (part.data.partStatus === true && part.data.partVisibility !== false) {
          let color = renderColor(part.data.partType);
          // let color ='#f9f9f9'

          return (
            <div className='propertyPane'>
              <div>
                <span className='label'>Part ID</span>
                <input type='text' className='form-control' disabled value={'part ' + index} />
              </div>
              <div>
                <span className='label'>Part Type</span>
                <select className='form-control' onChange={(e) => handelPartSelections(part, index, 'partType', e.target.value)} value={part.data.partType}>
                  <option>Select</option>
                  {renderCategeories()}
                </select>
              </div>
              <div>
                <span className='label'>Part Color</span>
                <input style={{ 'background': color }} className='form-control' readOnly={true} />
              </div>

              <div>
                <span className='label' >Part Name</span>
                <input type='text' className='form-control' value={part.data.partName} onChange={(e) => handelPartSelections(part, index, 'partName', e.target.value)} />
              </div>
              <div>
                <span className='label' >Z Value</span>
                <input 
                type='number' 
                className='form-control' 
                value={roundDecimals(part.data.partZ)} 
                onChange={(e) => handelPartSelections(part, index, 'partZ', e.target.value)} />
              </div>
              {/* <div>
          <span className='label'>Direction</span>
           <select className='form-control' >
             <option>Clockwise</option>
             <option>Anti-Clockwise</option>
           </select>
          </div> */}
              <div>
                <span className='label'>Area</span>
                <input 
                type='text' 
                value={roundDecimals(part.data.partArea) * -1} 
                className='form-control' 
                readOnly={true} />
              </div>
              <div>
                <span className='label'>Description</span>
                <input type='text' className='form-control' value={part.data.partDescription} onChange={(e) => handelPartSelections(part, index, 'partDescription', e.target.value)} />
              </div>
              <div>
                <span className='label'>Product Front</span>
                <select className='form-control' value={part.data.partDirection} onChange={(e) => handelPartSelections(part, index, 'partDirection', e.target.value)}  >
                  <option value='negativeX' key='negativeX'>Left</option>
                  <option value='positiveX' key='positiveX'>Right</option>
                  <option value='positiveY' key='positiveY'>Up</option>
                  <option value='negativeY' key='negativeY'>Down</option>
                </select>
              </div>

              <div>
                <span className='label'>Origin</span>
                <div className='origin'>
                  <div>X -{roundDecimals(part.data.partCalculatedOrigin.x)}</div>
                  <div>y -{roundDecimals(part.data.partCalculatedOrigin.y)}</div>
                  {((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) &&
                    <div className='resetOrigin'>
                      {(part.data.partOriginMetaData.length !== 0) &&
                        <button onClick={(e) => resetOriginSelection(e, index, selText)}>Reset</button>
                      }
                      <button onClick={(e) => handelOriginSelection(e, index, selText, 'select')}>{selText}</button>
                      {/* <button onClick={(e) => handelOriginSelection(e, index, selText, 'select')}>Edit</button> */}
                    </div>
                  }
                </div>
              </div>
              {props.editMode &&
                <>
                  <hr/>
                  <span>
                    <span className='label'>Select Part or Hole to Edit</span>
                    <div>
                      <select  
                        disabled={part.data.partHoleReference.length === 0 ? true : false}
                        className='form-control' value={partOrHole} onChange={handelPartOrHole}>
                        {part.data.partHoleReference.length !== 0 ? <option value="" >Select</option> : ""}
                        <option value='part' key='part'>Part</option>
                        {part.data.partHoleReference.length !== 0 ? <option value='hole' key='hole'>Hole</option> : ""}
                      </select>
                    </div>
                    {partOrHole === "hole" 
                    && currentHoleIndex === null 
                    && part.data.partHoleReference.length > 0? 
                      <div>Click on any Point from Hole to Select Hole</div> 
                      :""
                    }
                    {(partOrHole === "hole" && currentHoleIndex !== null) || (partOrHole === "part") ?
                      <div style={{
                        display:"flex", 
                        marginTop: "10px",
                        flexWrap: "wrap"
                        }}>
                        <div style={{padding: "5px"}} title="Delete point">
                          <button 
                          onClick={(e)=>handelEditOption(e, "delete_point")}
                          style={{
                            backgroundColor: editOption === "delete_point" ? "#ffffff" : "transparent",
                              border: editOption === "delete_point" ? "2px solid black" : "1px solid grey",
                              borderRadius: "5px",
                              padding: "5px",
                              outline: "none"
                            }}>
                            <RemovePointLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        <div style={{padding: "5px"}} title="Create Connetion">
                          <button
                            onClick={(e)=>handelEditOption(e, "create_connection")}
                            style={{
                              backgroundColor: editOption === "create_connection" ? "#ffffff" : "transparent",
                              border: editOption === "create_connection" ? "2px solid black" : "1px solid grey",
                              borderRadius: "5px",
                              padding: "5px",
                              outline: "none"
                              }}>
                            <AddConnectionLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        <div style={{padding: "5px"}}  title="Add Point On Path">
                          <button
                            onClick={(e)=>handelEditOption(e, "add_point_on_path")}
                            style={{
                              backgroundColor: editOption === "add_point_on_path" ? "#ffffff" : "transparent",
                              border: editOption === "add_point_on_path" ? "2px solid black" : "1px solid grey",
                              borderRadius: "5px",
                              padding: "5px",
                              outline: "none"
                            }}>
                            <AddMidPointLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        {partOrHole === "hole" ? 
                        <div style={{padding: "5px"}} title="Delete Hole">
                          <button
                            onClick={(e)=>handelEditOption(e, "delete_hole")}
                            style={{
                              backgroundColor: editOption === "delete_hole" ? "#ffffff" : "transparent",
                              border: editOption === "delete_hole" ? "2px solid black" : "1px solid grey",
                              borderRadius: "5px",
                              padding: "5px",
                              outline: "none"
                              }}>
                            <RemoveHoleLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        : ""}
                        <div style={{padding: "5px"}} title="Delete Connection">
                          <button
                          onClick={(e)=>handelEditOption(e, "delete_connection")}
                          style={{
                            backgroundColor: editOption === "delete_connection" ? "#ffffff" : "transparent",
                            border: editOption === "delete_connection" ? "2px solid black" : "1px solid grey",
                            borderRadius: "5px",
                            padding: "5px",
                            outline: "none"
                            }}>
                            <RemoveConnectionLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        <div style={{padding: "5px"}} title="Add New Point Anywhere">
                          <button
                          onClick={(e)=>handelEditOption(e, "add_point")}
                          style={{
                            backgroundColor: editOption === "add_point" ? "#ffffff" : "transparent",
                            border: editOption === "add_point" ? "2px solid black" : "1px solid grey",
                            borderRadius: "5px",
                            padding: "5px",
                            outline: "none"
                            }}>
                            <AddPointLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                        <div style={{padding: "5px"}} title="Update Point Coordinates">
                          <button
                          onClick={(e)=>handelEditOption(e, "update_cords")}
                          style={{
                            backgroundColor: editOption === "update_cords" ? "#ffffff" : "transparent",
                            border: editOption === "update_cords" ? "2px solid black" : "1px solid grey",
                            borderRadius: "5px",
                            padding: "5px",
                            outline: "none"
                          }}>
                            <MovePointLG style={{ height: '35px', width: '35px', stroke: 'black' }}/>
                          </button>
                        </div>
                      </div>
                    :""}
                  </span>
                  <hr></hr>
                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  &&  (editOption === "update_cords")
                  &&  (<span>
                      <div className='label'>Selected Point</div>
                      <div>
                        <span className='label'>X - Cord</span>
                        <input 
                        type='number' 
                        value={roundDecimals(newXCord)} 
                        onChange = {handleChangeXcord} 
                        className='form-control'/>
                      </div>
                      <div>
                        <span className='label'>Y - Cord</span>
                        <input 
                        type='number'
                        value={roundDecimals(newYCord)} 
                        onChange = {handleChangeYcord} 
                        className='form-control'/>
                      </div>
                      <div style={{marginTop: "10px"}}>
                        <button
                          className={(newXCord == xCord && newYCord == yCord) ? 'editButtonDisabled' : "editButton"}
                          disabled={(newXCord == xCord && newYCord == yCord) ? true : false }
                          onClick={(e) => handleUpdatePoint(e, index)}
                        >
                          Update Coordinates
                        </button>
                      </div> 
                  </span>
                  )}

                  {editOption === "delete_point" &&
                      <span>Click On The Points To Delete.</span>
                  }

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "add_point")
                  &&
                  (<span>
                    <div>Enter X and Y Coordinates Of New Point</div>
                    <div>
                      <span className='label'>X - Cord</span>
                      <input type='number' value={roundDecimals(newPointCords.x)} onChange = {handleChangeNewPointXCords} className='form-control' />
                    </div>
                    <div>
                      <span className='label'>Y - Cord</span>
                      <input type='number' value={roundDecimals(newPointCords.y)} onChange = {handleChangeNewPointYCords} className='form-control' />
                    </div>
                    <div>
                      <button
                      className={(newPointCords.x !== null && newPointCords.y !== null) ? 'editButton' : "editButtonDisabled"}
                      disabled={(newPointCords.x !== null && newPointCords.y !== null ) ? false : true}
                      onClick={(e)=>handleAddNewPoint(e, index)}
                      >Add New Point</button>
                    </div>
                  </span>
                  )}

                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "add_point_on_path")
                  &&(
                    <span>Click On Path To Add New Point</span>
                  )}

                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "create_connection")
                  &&
                  (<span>
                      <div>Select Any 2 Points To Create Connection</div>
                      <span>
                          <span className='label'><u>Point 1</u></span>
                          <div>
                            <span className='label'>X - Cord</span>
                            <input disabled type='number' value={roundDecimals(sourcePoint.x)} onChange = {handleSourcePointX} className='form-control' />
                          </div>
                          <div>
                            <span className='label'>Y - Cord</span>
                            <input disabled type='number' value={roundDecimals(sourcePoint.y)} onChange = {handleSourcePointY} className='form-control' />
                          </div>
                      </span>
                      <span>
                          <span className='label'><u>Point 2</u></span>
                          <div>
                            <span className='label'>X - Cord</span>
                            <input disabled type='number' value={roundDecimals(destinationPoint.x)} onChange = {handleDestinationPointX} className='form-control' />
                          </div>
                          <div>
                            <span className='label'>Y - Cord</span>
                            <input disabled type='number' value={roundDecimals(destinationPoint.y)} onChange = {handleDestinationPointY} className='form-control' />
                          </div>
                      </span>
                      <button
                        className={(sourcePoint.x !== null && sourcePoint.y !== null && destinationPoint.x !== null && destinationPoint.y !== null) ? 'editButton' : "editButtonDisabled"}
                        disabled={(sourcePoint.x !== null && sourcePoint.y !== null && destinationPoint.x !== null && destinationPoint.y !== null) ?  false : true}
                        onClick={handleCreateConnection}
                      >
                      Create Connection
                      </button>
                  </span>
                  )}

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && editOption === "delete_hole"
                  &&(
                    <div style={{marginTop: "10px"}}>
                      <button
                        className={selectedPointType === "hole" ? 'editButton' : "editButtonDisabled"}
                        disabled={selectedPointType !== "hole"}
                        onClick={handleDeleteHole}
                      >
                        Delete Hole
                      </button>
                    </div>
                  )}

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "delete_connection")
                  && (<span>Click on the line to to delete.</span>)
                  }
                  <div style={{marginTop: "10px"}}>
                    <button
                      className={undoData.length > 0 ? 'editButton' : "editButtonDisabled"}
                      disabled={undoData.length === 0}
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                    </div>
              </>
              }
            </div>  
        )}
      })
 
    }
  }

  const handelValChange = (e) => {
    setValue(e)

    if (e.e === 0 && e.f === 0) {
      centerAlignSvg(e)
    }
  }

  const handleAreaSelectionOn = () => {
    console.log("area selection called");
    setIsAreaSelectonOn(prev => !prev);
  }

  console.log("isAreaSelectionOn: ", isAreaSelectionOn);

  useEffect(() => {
    // Find the button by its selector
    const fitButton = document.querySelector('#root > div > div > div > div.outer > div.svgContainer > div.svgViewer > div > button:nth-child(5)');
    if (fitButton) {
      // Update the title attribute
      fitButton.setAttribute('title', 'Restore Viewer');
    }
  }, []);

  useEffect(() => {
      
    if((props.preview === false && moduleName === 'assembly-viewer') || (props.preview === true && moduleName === 'config-viewer')){

      const toolbar = document.querySelector("#root > div > div > div > div.outer > div.svgContainer > div.svgViewer > div");
      console.log("toolbar:" ,toolbar );
      const existingButton = toolbar?.querySelector('button[title="Box Select"]');
      if (toolbar && !existingButton) {
        const button = document.createElement('button');
        // Render the icon into the button
        const iconElement = <IconSelectDrag style={{ 
          height: '28px', width: '28px', stroke: 'white',  
        }} />;
        ReactDOM.render(iconElement, button);

        button.title = 'Box Select'
        button.style.backgroundColor = "#000"; 
        button.style.border = "none";
        button.style.marginTop = "3px";
        button.style.padding = "0"
        button.onclick = handleAreaSelectionOn;
        toolbar.appendChild(button);
     
      }
    }
  }, [props.preview, moduleName]);

  useEffect(()=>{
   
    if((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer')){
      const toolbar = document?.querySelector("#root > div > div > div > div.outer > div.svgContainer > div.svgViewer > div");
      const boxSelectButton = toolbar?.querySelector('button[title="Box Select"]');
      console.log("boxSelectButton: ", boxSelectButton)
      if (boxSelectButton !== null) {
        boxSelectButton.remove(); // Remove the element
      }
    }
  }, [props.preview, moduleName])

  useEffect(()=>{
    const toolbar = document.querySelector("#root > div > div > div > div.outer > div.svgContainer > div.svgViewer > div");
    const boxSelectButton = toolbar?.querySelector('button[title="Box Select"]');
    if(toolbar && boxSelectButton){
      const iconElement = <IconSelectDrag style={{ 
        height: '28px', width: '28px', stroke: isAreaSelectionOn ? 'rgb(28, 166, 252)' : 'white',  
      }} />;
      ReactDOM.render(iconElement, boxSelectButton);
    }
   
  }, [isAreaSelectionOn])

  return (
    <div className="svgContainer"
      onContextMenu={(e) => {
        e.preventDefault();
        if (Object.keys(selectedlayer.data).length > 0) {
          setClicked(true);
          props.setCreatePartStarted(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }
      }}
    >

      {renderMetaDataPropertyPane()}

      {clicked && (
        <div className='menuContextContainer' style={{ top: points.y + 'px', left: points.x + 'px' }}>
          <ul>
            <li onClick={(e) => props.createPart('part', null)}>Create Part</li>
            {/* <li onClick={(e) => props.deletePart('part', null)}>Delete Part</li> */}
            {
              parts.length > 0 && (
                <li onClick={(e) => props.createPart('hole', null)}>Create a Hole</li>
              )
            }
            <li onClick={hideSelectedParts}>Hide Selected Parts</li>
            <li onClick={hideNotSelectedParts}>Hide Not Selected Parts</li>
          </ul>
        </div>
      )}
      {/* (X:{mousePos.x}, Y:{mousePos.y}) */}
      {/* <button className="Edit Mode" onClick={(e) => centerAlignSvg(e)}>Fit (mode 2)</button> */}

      <ReactSVGPanZoom
        ref={Viewer}

        width={width} height={height}
        tool={tool} onChangeTool={setTool}
        value={value} onChangeValue={e => handelValChange(e)}
        miniatureProps={miniatureProps}
        onZoom={e => handelOriginPointRadius()}
        // onPan={e => console.log('pan')}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        background={"#000"}
        SVGBackground={"transparent"}
        className='svgViewer'
        preventPanOutside={false}


        detectAutoPan={false}
        scaleFactorMin={0.0001}
        // miniatureProps={{
        //   position: 'right'
        // }}
        toolbarProps={{
          position: 'right'
        }}
        style={{
          margin: 'auto',
         
        }}
        onClick={event => handleClick(event)}
        SVGStyle={{ width: '50%', marginRight: 0,  }}
      >
        

        <svg id='mySvg' transform="scale(1, -1)"
          xmlns="http://www.w3.org/2000/svg"
          width={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer') ?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}
        >
         

          <defs>
            <marker
              id="dot"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="5"
              markerHeight="5">
              <circle cx="5" cy="5" r="5" fill="#fff" />
            </marker>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" fill='#fff' />
            </marker>
          </defs>

          {(props.preview===true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer') ?
          <style>
            {`
          /* Define your CSS rules here */
          g.selected path{
            marker: url(#arrow);
          }
        `}
          </style>
          : ""}

          <g transform="scale(1, -1)">

            {!(props.preview === true && moduleName === 'assembly-viewer') ? 
              <path 
              d={`M ${rectangleCords?.domainMinX}, ${rectangleCords?.domainMinY} ${rectangleCords?.domainMaxX}, ${rectangleCords?.domainMinY}, ${rectangleCords?.domainMaxX}, ${rectangleCords?.domainMaxY} ${rectangleCords?.domainMinX}, ${rectangleCords?.domainMaxY} Z`} 
              fill="#000" 
              stroke="yellow"
              stroke-width={Viewer?.current?.getValue()?.a ? 1/Viewer?.current?.getValue()?.a : 1}
              stroke-dasharray={Viewer?.current?.getValue()?.a ? (5/Viewer?.current?.getValue()?.a, 5/Viewer?.current?.getValue()?.a) : `0.5,0.5`}
              />
              :""
            }

            {props.paths}
            {renderParts()}

            {(startPoint.x !== null 
            && startPoint.y !== null 
            && endPoint.x !== null 
            && endPoint.y !== null
            && isAreaSelectionOn
            && ((props.preview === false && moduleName === 'assembly-viewer') || (props.preview === true && moduleName === 'config-viewer'))) ?
              <path 
              d={`M ${startPoint.x}, ${startPoint.y*-1} ${endPoint.x}, ${startPoint.y*-1}, ${endPoint.x}, ${endPoint.y*-1} ${startPoint.x}, ${endPoint.y*-1} Z`} 
              fill="grey" 
              stroke="grey" 
              stroke-width={Viewer?.current?.getValue()?.a ? 1/Viewer?.current?.getValue()?.a : 1} />
            :""}
           
            {
            ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && getOriginForConfig()
            }  
          </g>
        </svg>
      </ReactSVGPanZoom>
    </div>
  );
};

export default SvgViewer;
