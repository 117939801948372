
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import {deleteAllActions, reaetSendRecordDataToBackEndReduce, saveConfig, updateSvgPartsMetaData} from '../../axios/index'  
import {Link} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
 
function SaveConfigPopup(props) {  
  const dispatch = useDispatch();
  const partData = useSelector((state)=>state.svgViewerPartData);
  const [saveOptions, setSaveOptions] = React.useState(props.modal.saveType);
  const [error, setError] = React.useState(false);
  const [statusType, setStatusType] = React.useState('draft');
  const parts = useSelector((state) => state.svgViewerPartData)
  const svgSource = useSelector((state) => state.svgViewerData);
  const UploadOptions = useSelector((state) => state?.UploadFieldsOptions);
  
  // const handleEvent = (status)=>{
  //   return props.handleEditStatus(status)
    
  // }
  // const handleSave = (e) =>{
  //   return props.handleSaveEvent(true)
  // }

const handleScale = (e) => {
    props.setSelectedScale(e.target.value)
}

const updateConfigMetaData = (key, val) =>{
    dispatch(updateSvgPartsMetaData({key:key, val:val}))
}

const calculateMidPointAndRange = (allCoordinates) => {

  function flattenArray(arr) {
    return arr.reduce(function(acc, val) {
        return acc.concat(Array.isArray(val) ? flattenArray(val) : val);
    }, []);
  }

  let flattenedArray = flattenArray(allCoordinates);
  
  const groupedCoordinates = [];

  // Loop through the flat array, grouping every 2 values into [x, y] pairs
  for (let i = 0; i < flattenedArray.length; i += 2) {
      const x = flattenedArray[i];
      const y = flattenedArray[i + 1];
      groupedCoordinates.push([x, y]);
  }

  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let midX, midY;
  
  groupedCoordinates?.forEach((coordSet, index) => {

    let [x, y] = coordSet;
    // Update minimum and maximum values
    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });
  // Calculate midpoint
  midX = (minX + maxX) / 2;
  midY = (minY + maxY) / 2;
  
  return { 
    "midpoint": [midX, midY],
    "ranges": {
      "x": {
        "min": minX,
        "max": maxX
      },
      "y":{
        "min": minY,
        "max": maxY
      }
    }
  }

};

const convertCoordinates = (coordinates, fromScale, toScale) => {

  let tempCoordinates = coordinates;

  // Base case: if arr is not an array, return (end of recursion)
  if (!Array.isArray(tempCoordinates)) {
    return;
  }

  // Iterate through each element of the array
  for (let i = 0; i < tempCoordinates.length; i++) {
    // If the current element is an array, recursively call updateNestedArray
    if (Array.isArray(tempCoordinates[i])) {
      convertCoordinates(tempCoordinates[i], fromScale, toScale); // Recursively process nested array
    } else {
      // Otherwise, update the value
      tempCoordinates[i] = convertToSelectedScale(tempCoordinates[i], fromScale, toScale);
    }
  }

  return tempCoordinates;
}

const convertToSelectedScale = (value, fromScale, toScale) => {
  let data = value;
  if(fromScale == 1 && toScale == 2) //convert from INCH to MM
    data *= 25.4;
  else if(fromScale == 1 && toScale == 3) //convert from INCH to CM
    data *= 2.54;
  else if(fromScale == 2 && toScale == 1) //convert from MM to INCH
    data /= 25.4;
  else if(fromScale == 2 && toScale == 3) //convert from MM to CM
    data /= 10;
  else if(fromScale == 3 && toScale == 1) //convert from CM to INCH
    data /= 2.54;
  else if(fromScale == 3 && toScale == 2) //convert from CM to MM
    data *= 10;

  return data;
}

const convertAreaToSelectedScale = (value, fromScale, toScale) => {

  let data = value;
  if(fromScale == 1 && toScale == 2) //convert from INCH to MM
    data *= (25.4 * 25.4);
  else if(fromScale == 1 && toScale == 3) //convert from INCH to CM
    data *= (2.54 * 2.54);
  else if(fromScale == 2 && toScale == 1) //convert from MM to INCH
    data /= (25.4 * 25.4);
  else if(fromScale == 2 && toScale == 3) //convert from MM to CM
    data /= (10 * 10);
  else if(fromScale == 3 && toScale == 1) //convert from CM to INCH
    data /= (2.54 * 2.54);
  else if(fromScale == 3 && toScale == 2) //convert from CM to MM
    data *= (10 * 10);

  return data;
}

const getConvertedTransformedSVGPath = (coordinates) => {
  let newPath = "";
  let flatArray = coordinates.flat(Infinity);

  const extractCoordinates = (data) => {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (Array.isArray(data[i]) && data[i].length === 2 && typeof data[i][0] === 'number' && typeof data[i][1] === 'number') {
          // Handle the first point with 'M', the last point with 'Z', and others with space
          if (i === 0) {
            newPath += `M ${data[i][0]}, ${data[i][1]}`;
          } 
          // else if (i === data.length - 1) {
          //   newPath += ` ${data[i][0]}, ${data[i][1]}Z`;
          // } 
          else {
            newPath += ` ${data[i][0]}, ${data[i][1]}`;
          }
        } else if (Array.isArray(data[i])) {
          // Recursively handle nested arrays
          extractCoordinates(data[i]);
        }
      }
    }
  };

  extractCoordinates(coordinates);
  if(flatArray[0] === flatArray[flatArray.length-2] && flatArray[1] === flatArray[flatArray.length-1]){
    newPath = newPath + 'Z';
  }
  return newPath;
};

const handleSaveEvent=(e)=>{
  
  let tempParts  = cloneDeep(partData);     

  if(!props.editMode){
    let scale;
    if(props.moduleName === "assembly-viewer"){
      scale = svgSource.data.assemblyData.metaData.containerMetaData.cadScale;
    }else if(props.moduleName === "config-viewer"){
      let tempScale = tempParts.data.configData.metaData.configUnits
      if(tempScale == 'cm'){
        scale = 3
      }else if(tempScale == 'mm'){
        scale = 2
      }else if(tempScale == 'inch'){
        scale = 1
      }
    }

    tempParts?.data?.configData?.partData?.forEach(part => {
    
      if (scale == 1 && props.selectedScale == 2) {
        //convert from INCH to MM
        tempParts.data.configData.metaData.configUnits = 'mm';

      } else if(scale == 1 && props.selectedScale == 3){
        //convert from INCH to CM
        tempParts.data.configData.metaData.configUnits = 'cm';
      
      }else if(scale == 2 && props.selectedScale == 1){
        //convert from MM to INCH
        tempParts.data.configData.metaData.configUnits = 'inch';
      
      }else if(scale == 2 && props.selectedScale == 3){
        //convert from MM to CM
        tempParts.data.configData.metaData.configUnits = 'cm';
      
      }else if(scale == 3 && props.selectedScale == 1){
        //convert from CM to INCH
        tempParts.data.configData.metaData.configUnits = 'inch';

      }else if(scale == 3 && props.selectedScale == 2){
        //convert from CM to MM
        tempParts.data.configData.metaData.configUnits = 'mm';

      }else if(scale == props.selectedScale){
        //if current and new selected scales are same
        if(scale == 1)
          tempParts.data.configData.metaData.configUnits = 'inch';
        else if(scale == 2)
          tempParts.data.configData.metaData.configUnits = 'mm';
        else if(scale == 3)
          tempParts.data.configData.metaData.configUnits = 'cm';
      }

      const newOriginX = convertToSelectedScale(part.data.partCalculatedOrigin.x, scale, props.selectedScale);
      const newOriginY = convertToSelectedScale(part.data.partCalculatedOrigin.y, scale, props.selectedScale);
      part.data.partCalculatedOrigin = {x: newOriginX, y: newOriginY}
      part.data.partArea = convertAreaToSelectedScale(parseFloat(part.data.partArea), scale, props.selectedScale);
      part.data.partZ = convertToSelectedScale(parseFloat(part.data.partZ), scale, props.selectedScale);
      
      part.data.partTransformedCoordinates = convertCoordinates(part.data.partTransformedCoordinates, scale, props.selectedScale);
      part.data.partTransformedSvgPath = getConvertedTransformedSVGPath(part.data.partTransformedCoordinates);

      part?.data?.partHoleReference?.forEach(hole => {

        hole.partCalculatedOrigin = {x: newOriginX, y: newOriginY}
        hole.partArea = convertAreaToSelectedScale(parseFloat(hole.partArea), scale, props.selectedScale);
        hole.partZ = convertToSelectedScale(parseFloat(hole.partZ), scale, props.selectedScale);
        hole.partTransformedCoordinates = convertCoordinates(hole.partTransformedCoordinates, scale, props.selectedScale);
        hole.partTransformedSvgPath = getConvertedTransformedSVGPath(hole.partTransformedCoordinates);
      })
    });
  }

  const allTransformedCoordinates = tempParts?.data?.configData?.partData?.map(part => part.data.partTransformedCoordinates);

  const {midpoint, ranges} = calculateMidPointAndRange(allTransformedCoordinates);
  tempParts.data.configData.metaData.midpoint = midpoint;
  tempParts.data.configData.metaData.ranges = ranges;
          
  //if(parts.data.configData.partData.length>0){
    //  if(parts.data.configData.)
    if(parts.data.configData.metaData.assemblyID!=='' && parts.data.configData.metaData.configName!==''){
      if(saveOptions==='new'){
         tempParts.data.configData.metaData.configId='';  
         tempParts.data.configData.type ='new';       
         tempParts.data.configData.metaData.configStatus =statusType;   
         dispatch(saveConfig({configData:tempParts.data.configData}))
      }
      else if(saveOptions === 'update'){
        tempParts.data.configData.type ='existing';   
        tempParts.data.configData.metaData.configStatus =statusType;   
        dispatch(saveConfig({configData:tempParts.data.configData}))

      }
    // props.closeModal(e)
    }
    else{
      setError(true)
    }
  // }
  // else {

  // }   
  
 // return props.saveRecordEvent({saveType:saveOptions, statusType:statusType, module:props.modal.module})
    dispatch(deleteAllActions({}));
}

const renderLoader = ()=>{
  if(partData.loading===true){
    return(
      <div className='fixedLoader'><img src={loader} className='loaderr' /></div>
    )
  }
}

useEffect(()=>{
  if(props.moduleName == 'assembly-viewer'){
    props.setSelectedScale(svgSource.data.assemblyData.metaData.containerMetaData.cadScale)
  }
},[])


const renderCardBody = () =>{
  
 
  if(partData.loading===false && partData.responseType==''){
     
    let currentModuleeditStatus = '';
    if(partData.data.configData?.type==="new"){
      currentModuleeditStatus= 'draft'
    }
    else if(partData.data.configData.metaData?.configStatus!==undefined){
      currentModuleeditStatus= partData.data.configData.metaData.configStatus;
    }
    let configId = partData.data.configData.metaData?.configId;
    
    return(
      <div className="card-body">
          <p>{props.modal.info}</p>
          <form>
            <div className="row">
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio1" value="new" checked = {saveOptions=='new'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio1">
                    Save as New <span>{props.modal.module}</span>
                    </label>
                  </div>
                  {/* {configId!==undefined && configId!==''?(
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="newVersion"  checked = {saveOptions=='newVersion'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio2">
                    Save as New <span>{props.modal.module}</span> Version
                    </label>
                  </div>
                  ):null} */}
                  {configId!=='' && currentModuleeditStatus==='draft'?(
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="update"  checked = {saveOptions=='update'} onChange={(e)=>setSaveOptions(e.target.value)} />
                        <label class="form-check-label" for="radio2">
                        Update current <span>{props.modal.module}</span> Version
                        </label>
                      </div>
                    ):null
                  }
                  
              </div>
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio3" value="inProduction"   checked = {statusType=='inProduction'} onChange={(e)=>setStatusType(e.target.value)} />
                    <label class="form-check-label" for="radio3">
                    Publish this <span>{props.modal.module} to <b>Production</b></span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio4" value="draft"    checked = {statusType=='draft'} onChange={(e)=>setStatusType(e.target.value)}/>
                    <label class="form-check-label" for="radio4">
                    Save this <span>{props.modal.module} to <b>Drafts</b></span>
                    </label>
                  </div>
              </div>
            </div> 
            {props.moduleName === "assembly-viewer" ?
            
            <div>
              <hr></hr>
              <p>Please enter below details to save configuration.</p>
              <div>
                Confiuration Name -                    
                <input type='text' value={partData?.data?.configData?.metaData?.configName} className='form-control edit-input' onChange={(e)=>{updateConfigMetaData('configName',e.target.value )}} />
              </div>
              <div>
                Description -                   
                <input type='text' value={partData?.data?.configData?.metaData?.configDescription} className='form-control edit-input' onChange={(e)=>{updateConfigMetaData('configDescription',e.target.value )}} />
              </div>
             
              <div>
                  <div >
                    Configuration Scale
                  </div>
                  <div className="dropdown">
                    <select
                      className='form-control edit-input'
                      id={"cadScale"}
                      key={"cadScale"}
                      value={props.selectedScale}
                      onChange={handleScale}
                    >
                      {UploadOptions?.data?.scaleOptions?.map((value) => (
                        <option value={value?.id}>{value?.scaleName}</option>
                      ))}
                    </select>
                  </div>
                </div>
            </div>
            : ""}
          </form>
        
        </div>
    )
  }
  else if(partData.loading===false &&  partData.responseType!==''){
    if(partData.responseType==='Failed'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='failed'><i className='now-ui-icons ui-1_simple-remove'></i>&nbsp; {partData.responseType}</div>
                  <div className='failedTxt'>{partData.responseDesc}</div>
             </div>
      </div>)

    }
    else if(partData.responseType==='success'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='successMsg'><i className='now-ui-icons ui-1_check'></i>&nbsp; {partData.responseType}</div>
                  <div className='successTxt'>{partData.responseDesc}</div>
             </div>
      </div>)

    }
     
  }
  else if(partData.loading){
    return(<div className="card-body">
    {renderLoader()}
</div>)
  }
}
const renderingTxt=()=>{
  let errtxt = 'Error';
  if(partData.responseDesc.length>0){    
    Object.keys(partData.responseDesc[0]).map((key)=>{
      errtxt = partData.responseDesc[0][key]
    })
  }
 
  return(
    errtxt
  )
}
const renderingTxt2=()=>{
  let errtxt = 'Success';
  if(partData.responseDesc!==''){
    
     
      errtxt = partData.responseDesc
    }
  
 
  return(
    errtxt
  )
}

const renderFooterBtn =() =>{
  // saveConfig({configData:svgViewerPartData.data.configData}
    // return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    //    <button type="button" className="btn btn-primary" disabled={error} onClick={(e)=>handleSaveEvent(e)} ><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>
    //    )
  if(partData.responseType==='' && partData.loading===false){
    return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" onClick={(e)=>handleSaveEvent(e)} ><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
  else if(partData.responseType==='Failed' && partData.loading===false){
    return(
      <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Close</button>
    )
  }
  else if(partData.responseType==='success' && partData.loading===false){
    return(
      <button type="button" className="btn btn-primary"   onClick={(e)=>{props.closeModal(e)}} > &nbsp;Ok</button>
    )
  }
  else if(partData.loading===true){
    return(<> <button type="button" className="btn btn-secondary"  disabled><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" disabled><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
}
  return (
    <div className="modal fade show" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><i className={props.modal.icon}></i> {props.modal.title}</h5>
            <button type="button" className="close"   aria-label="Close" onClick={(e)=>{props.closeModal(e)}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card downloadFile" style={{'boxShadow':'none'}}>
                  {renderCardBody()}                  
                </div>
              {error && 
                <p style={{'color':'red',background: "#f0eeee", padding:'10px'}}>Configuration file name is mandatory!<br />
                </p>
              }  
          </div>
          <div className="modal-footer">
           {renderFooterBtn()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveConfigPopup;
